@use "../../assets/sass/index" as *;
.otherpage_text {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.otherpage_text h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  @include mq(700px) {
    font-size: 30px;
    line-height: 40px;
  }
  @include mq(500px) {
    text-align: left;
    width: 85%;
  }
}

.cover_grid_other {
  width: 85%;
  margin: auto;
  margin-top: 95px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .each_other_card {
    width: 31%;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 69px;
    @include mq(768px) {
      width: 100%;
    }
    .top_side {
      height: 254px;
      width: 100%;
      video,
      img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .btm_side {
      padding: 28px 34px;
      width: 100%;

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 42px;
      }
      .last {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: #000000;
      }
    }
  }
}

.otherpage_search {
  background: #ffffff;
  border: 0.841269px solid #e5e5e5;
  box-shadow: 0px 28.3779px 28.3779px rgba(243, 243, 243, 0.4);
  border-radius: 13.5636px;
  width: 500px;
  height: 57px;
  display: flex;
  padding: 0 28px;
  align-items: center;
  margin-top: 57px;
  @include mq(600px) {
    width: 90%;
  }
  img {
  }
  input {
    height: 100%;
    flex: auto;
    border: none;
    background: none;
  }
}

.otherpage_btn {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include mq(500px) {
    justify-content: unset;
    width: 85%;
    margin: auto;
    margin-top: 35px;
  }
}

.otherpage_btn span {
  margin-right: 34px;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 112.23px;
  font-weight: 400;
  font-size: 13.1492px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  color: #000000;
  &:last-child {
    margin-right: 0;
  }
  @include mq(500px) {
    margin-bottom: 10px;
  }
}

.otherpage_card {
  margin-top: 95px;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr;
  //   grid-template-rows: 1fr 1fr 1fr;
  //   column-gap: 31px;
  //   row-gap: 70px;
  display: flex;
  flex-wrap: wrap;
}

// .otherpage_card_items {
//   // width: 399.81px;
//   width: 32%;
//   height: 428.37px;
//   background: #ffffff;
//   box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
//   border-radius: 20px;
// }

// .otherpage_card_items video {
//   border-radius: 20px 20px 0 0;
//   width: 100%;
// }
// .otherpage_card_items img {
//   border-radius: 20px 20px 0 0;
//   width: 100%;
// }

// .otherpage_card_items div {
//   background-color: white;
//   padding: 20px;
// }

// .otherpage_card_items div h3 {
//   font-size: 20px;
// }

// .otherpage_card_items div p {
//   margin-top: 42px;
//   font-size: 12px;
// }
