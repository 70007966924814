.card_img img{
    margin-left: 20px;
    cursor: pointer;
}

.card_container{
    display: flex;
}

.empty_card_container{
    margin-left: 20rem;
}