#currency-pair-cards {
  background: url("../../assets/drawables/landing_currency_pair_cards.svg") no-repeat center;
  width: 458px;
  height: 415px;
}

.transactions {
  display: flex;
  align-items: center;
  background: #f5faff;
  padding: 6rem 10rem;
  gap: 12px;
}

.transactions .transactions-left {
  flex: 1.5;
}

.transactions .transactions-right {
  flex: 1;
}
