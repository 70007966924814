@use "../../../assets/sass/index" as *;
.pd_bank_ice {
  background-color: rgba($color: #e5e5e5, $alpha: 0.09);
  min-height: 100vh;
  .create_box {
    border: 1.31592px dashed #aaaaaa;
    box-sizing: border-box;
    border-radius: 12px;
    height: 129px;
    font-weight: 600;
    display: flex;
    cursor: pointer;
    color: #666666;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    width: 269px;
    &.inList {
      width: 100%;
    }
  }

  .cover_remainder {
    width: 100%;
    padding: 40px;
  }
  .filter_ice {
    @include filter_ice;
  }
}
.back_box {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 5px;
  }
  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #173963;
  }
}
