.article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 7.5rem 5.5rem;
}

.article h3 {
   font-family: sqMarketBold;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #173e6f;
  margin-bottom: 4.5rem;
}

.article .article-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 3.5rem;
}

.article .artcleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
