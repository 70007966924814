@use "../../assets/sass/index" as *;
.cover_ham {
  display: none;

  width: 100%;
  justify-content: flex-end;
  padding: 10px;
  @include mq(900px) {
    display: flex;
  }
}
