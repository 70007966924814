@use "../../assets/sass/index" as *;

.pd-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(23, 57, 99, 0.85);
  backdrop-filter: blur(4px);
  z-index: 20;
  overflow: hidden;
  @include flex();
  .close-modal-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .title {
    margin-bottom: 1rem;
    color: black;
    font-size: 1rem;
  }
  &.small {
    .popBox2 {
      max-width: 400px;
      display: flex;
      justify-content: center;
    }
  }
  .popBox2 {
    @include modalLay;
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    width: 90vw;

    max-width: 526px;
    padding: 39px 35px;
    padding-bottom: 77px;
    .btn-wrap {
      display: flex;
      justify-content: flex-end;
      button {
        min-width: 118px;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .cancel {
        width: 26px;
      }
      p {
        font-weight: 600;
        font-size: 16.9237px;
        line-height: 21px;
        margin-bottom: 0;
        color: #0d458a;
        &.red {
          color: #f34018;
        }
      }
    }

    .input-icon-wrap,
    .select-mui {
      background-color: #f7fafc;
    }
  }
  .msg-modal {
    min-height: 380px;
    .icon-wrap {
      width: 82px;
      height: 82px;
      border-radius: 50%;
      @include flex();
      svg {
        font-size: 2.5rem;
      }
    }
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    .title {
      font-size: 1.2rem;
      margin: 0;
      font-weight: 600;
    }
    button {
      margin-top: 1rem;
    }
  }
  &.slant {
    .popBox2 {
      @include modalLay;
      padding: 0;
      max-width: 440px;
    }
  }
}
