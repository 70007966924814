@use "../../../../../../assets/sass/index" as *;

.pd_suc_mod {
  img {
    margin-left: -10px;
  }
  .verify {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #3aa64c;
    margin-top: 55px;
    margin-bottom: 13px;
  }
  .not {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    color: #000000;
  }
  .close {
    font-weight: 600;

    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin: auto;
    display: block;
    border: none;
    background: none;
    margin-top: 55px;
    color: #173e6f;
    margin-bottom: 69px;
  }
}
