@use "../../../../../assets/sass/index" as *;
.pd_chart_section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 85px;
  padding: 0 60px;
  flex-wrap: wrap;
  .left_chart {
    width: 60%;
    background: #ffffff;
    border: 0.567366px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 19.1385px 19.1385px rgba(243, 243, 243, 0.4);
    border-radius: 17.2247px;
    @include mq(768px) {
      width: 100%;
      margin-bottom: 20px;
    }
    .cover_chart {
      padding: 30px 38px 22px;
      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 60px;
      }
      .chart_indicators {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 30px;
        flex-wrap: wrap;
      }
    }
    .move_box {
      display: flex;
      width: 100%;
      border-top: 0.76554px solid #eaeaea;
      padding: 15px;
      justify-content: center;
      align-items: center;
    }
  }
  .right_chart {
    width: 38%;
    @include mq(768px) {
      width: 100%;
    }
  }
}
.moveright {
  width: 32px;
  height: 32px;
  margin-right: 18px;
  border-radius: 50%;
  border: 0.887989px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    transform: rotate(180deg);
  }
  &:last-child {
    margin-right: 0;
  }
}
