@use "assets/sass/index" as *;

.pd_send_money {
  .main_aspect {
    @include main_aspect;

    .cover_tender {
      width: 100%;
      margin-top: 20px;
      .changeBox {
        @include change_box;
      }
      .save {
        font-weight: 400;
        font-size: 11px;
        display: flex;
        align-items: center;
        line-height: 13px;
        margin-bottom: 28px;
        color: #0d458a;
        img {
          margin-left: 5px;
        }
      }
      .eachdol {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        &:last-child {
          margin-bottom: 0;
        }
        img {
          margin-right: 13px;
          width: 13px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          line-height: 17px;
          color: #5c5c5c;
          img {
            margin: 0;
            margin: 0 13px;
          }

          .bold {
            color: black;
            margin-right: 13px;
            font-weight: 600;
            &.blue {
              color: $primary;
              margin-right: 0;
              margin-left: 8px;
            }
          }
        }
      }
      .last_div {
        display: flex;
        align-items: center;
        margin-top: 16px;
        img {
          margin-right: 3px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #5c5c5c;
        }
      }
      a.cancel {
        font-weight: 600;
        text-decoration: none;
        display: block;
        margin-top: 50px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #0d458a;
      }
    }
  }
}
.first_text_send {
  text-align: left;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 21px;

  color: #0d458a;
}
