// flex mixin
@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}
$nav-shadow: 0px 2px 8px rgba(5, 38, 105, 0.1);

@font-face {
  font-family: myFirstFont;
  src: url("../fonts/SQ\ Market\ Regular\ Regular.ttf");
}

$font-reg: myfirstfont;
@mixin webkit2 {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f0f2f3;
    background: #c4c4c4;
    border-radius: 30px;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: white;
  }
}
@mixin webkitnone {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f0f2f3;
    background: #c4c4c4;
    border-radius: 30px;
  }

  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: white;
  }
}
@mixin mob_nav {
  flex-direction: column;
  width: 100%;
  position: absolute;
  inset: 0;
  box-shadow: $nav-shadow;
  top: 72px;
  background-color: white;
  height: max-content;
  align-items: center;
  z-index: 20;
  padding: 0 1.5rem;
  justify-content: center;
  transition: clip-path 0.4s ease;
  clip-path: inset(0 0 100% 0);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba($color: #000000, $alpha: 0.5);
}
@mixin mq($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}
@mixin drop_box {
  background: #ffffff;
  border: 0.7px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 21px;
  position: relative;
  height: 48px;
}
@mixin stat_btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .filter_btn {
    background: #ffffff;
    border: 1px solid #c5cfdc;
    box-sizing: border-box;
    border-radius: 112.23px;
    outline: none;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 13.1492px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    margin-right: 21px;
    cursor: pointer;
    @include mq(650px) {
      margin-bottom: 5px;
    }
    &.active {
      background: #eaac0c;
      color: white;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
@mixin table_mixin {
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
  thead {
    border-bottom: 1.18778px solid #e1e1e1;
    th {
      padding: 20px 15px;
      font-weight: 600;
      font-size: 15.6183px;
      line-height: 19px;
      text-align: right;
      text-align: left;
      // color: #5c5c5c;
      border-bottom: 1.18778px solid #e1e1e1;
      &.center {
        text-align: center;
      }
    }
  }
  tbody {
    tr:hover {
      background-color: #f5faff;
    }
    td {
      padding: 15px;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
    .serial {
      font-weight: 500;
      font-size: 14.4305px;
      line-height: 18px;

      color: #000000;
    }
    .red {
      color: #f34018;
    }

    .green {
      // color: #34e36f;
      color: #108a5b;
    }
    .trend {
      svg {
        width: 150px;
        display: block;
        margin: auto;
      }
    }
    .country {
      display: flex;
      align-items: flex-start;

      img {
        width: 26px;
        height: 20px;
        object-fit: cover;
        border-radius: 2px;
      }

      .textSide {
        margin-left: 15px;
        .top {
          font-weight: 500;
          font-size: 16.8061px;
          line-height: 21px;

          color: #000000;
          margin-bottom: 3.75px;
        }
        .bottom {
          font-weight: 300;
          font-size: 13.0656px;
          line-height: 16px;

          color: #000000;
        }
      }
    }

    .invest {
      background: #deedff;
      border-radius: 71.2668px;
      font-weight: 400;
      font-size: 14.4305px;
      line-height: 18px;
      border: none;
      padding: 8px 25px;
      text-align: center;

      /* Primary Color */

      color: #173963;
    }
  }
}
@mixin filter_ice {
  display: flex;
  margin-bottom: 36px;
  .eachfilter_ice {
    margin-right: 25px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;

      color: #5c5c5c;
      margin-bottom: 5px;
    }
    .act_line {
      background: #eaac0c;
      width: 20px;
      height: 4px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin: auto;
      display: none;
    }
    &.active {
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        margin-bottom: 5px;
        color: #0d458a;
      }
      .act_line {
        display: block;
      }
    }
  }
}
@mixin modalLay {
  background: #ffffff;
  border-radius: 11px;
  padding: 1.5rem 30px;
  margin: auto;
  max-height: 90vh;
  overflow-y: auto;
  @include webkit3;
}
// webkit scrollbar mixins
@mixin webkit {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f0f2f3;
  }

  &::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    background: #eee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #eee;
  }
}

@mixin drop_box {
  background: #ffffff;
  border: 0.7px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 21px;
  position: relative;
  height: 48px;
}

@mixin webkit3 {
  &::-webkit-scrollbar {
    height: 0px;
    width: 8px !important;
    // background: #eee;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    // background: #eee;
    background: #e5e5e5;
    border-radius: 100px;
    min-height: 40px;
  }
}
@mixin webkit4 {
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    // background: #eee;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    // background: #eee;
    background: #e5e5e5;
    border-radius: 100px;
    min-height: 40px;
  }
}

@mixin label {
  color: #0d458a !important;
  margin-bottom: 8px;
  font-weight: 500;
  @include flex($justify: space-between);
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}
@mixin center_container {
  width: 40%;
  margin: auto;
  margin-top: 100px;
  @include mq(968px) {
    width: 60%;
  }
  @include mq(568px) {
    width: 80%;
  }
}
@mixin check {
  display: flex;
  margin: 20px 0px;
  align-items: center;

  .css-1sg8fd1-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0;
  }
  .css-1sg8fd1-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-1sg8fd1-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #eaac0c;
  }
  .css-1sg8fd1-MuiButtonBase-root-MuiCheckbox-root {
    color: #eaac0c;
  }
  p {
    font-weight: 300;
    font-size: 9.63183px;
    line-height: 12px;
    text-align: center;
    margin-left: 5px;
    a {
      text-decoration: none;
      color: $primary;
      font-weight: 600;
    }
  }
}
@mixin chart_box {
  width: 69%;
  border: 0.698392px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 17.4598px 17.4598px #f9fbff;
  border-radius: 15.7138px;
  padding: 41px 37px;
  @include mq(900px) {
    padding: 30px 10px;
  }
  @include mq(1100px) {
    width: 100%;
    margin-bottom: 10px;
  }
  .chart_head {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 17px;
  }
  .chart_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq(900px) {
      flex-direction: column;
      align-items: unset;
    }
    .left {
      display: flex;
      align-items: center;
      @include mq(900px) {
        margin-bottom: 10px;
      }
      p {
        font-weight: 300;
        font-size: 10.8157px;
        line-height: 13px;
        margin-left: 3px;
        color: #5c5c5c;
      }
    }
    .right {
      display: flex;
      align-items: center;

      @include mq(900px) {
        flex-wrap: wrap;
      }
      .day_filter {
        background: #f5faff;
        border: 0.764062px solid #cbddf3;
        box-sizing: border-box;
        box-shadow: 15.2812px 7.64062px 15.2812px #edf5ff;
        border-radius: 15.2812px;
        padding: 6px;
        width: max-content;
        display: flex;
        align-items: center;
        margin-right: 13px;
        @include mq(900px) {
          margin-top: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
        .each {
          border-radius: 15.2812px;
          padding: 6px 9px;
          margin-right: 17px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .chart_toggle {
            path {
              fill: #666666;
            }
            rect {
              fill: #666666;
            }
          }
          p {
            font-weight: 700;
            font-size: 10.0468px;
            line-height: 12px;

            text-align: center;

            color: #666666;
          }
          &.active {
            background: #eaac0c;
            .chart_toggle {
              path {
                fill: white;
              }
              rect {
                fill: white;
              }
            }
            p {
              color: white;
            }
          }
        }
      }
    }
  }
}

@mixin info_container {
  background: #ffffff;
  border: 0.868176px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 21.7044px 21.7044px #f9fbff;
  border-radius: 19.534px;
  width: 69%;
  margin-top: 49px;
  @include mq(1100px) {
    width: 100%;
  }
  .top_side {
    padding: 44px 39px;
    border-bottom: 0.868176px solid #eaeaea;
    .first_text {
      margin-bottom: 34px;
      text-align: left;
      color: #000000;
    }
    .info_flex {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .each_info {
        margin-bottom: 10px;
        .top {
          font-weight: 300;
          font-size: 12.0993px;
          line-height: 15px;
          margin-bottom: 15px;
          color: #000000;
        }
        .bottom {
          p.text {
            font-weight: 600;
            font-size: 12.0993px;
            line-height: 15px;
            color: #000000;
          }
          .line {
            display: flex;
            align-items: center;
            p {
              font-weight: 500;
              font-size: 13.1492px;
              line-height: 16px;
              margin-left: 4px;
              color: #000000;
            }
          }
          .risk {
            margin-top: 0;
            .line {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .bottom_side {
    padding: 32px 39px 42px;
    .story {
      font-weight: 300;
      font-size: 13.0993px;
      line-height: 24px;
      /* or 183% */

      color: #000000;
      margin-bottom: 25px;
    }
    a {
      font-weight: 600;
      text-decoration: none;
      font-size: 13.1845px;
      line-height: 16px;

      color: #eaac0c;
    }
  }
}

@mixin tag {
  display: flex;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 10.4921px;
  line-height: 13px;
  width: max-content;
  color: #0d458a;
  margin-top: 15px;
  background: #ecf4ff;
  border-radius: 4.23435px;

  .redtag {
    color: #f34018;
    &.green {
      color: #34e36f;
    }
  }
  img {
    margin-right: 5px;
  }
}
@mixin news_flex_grid {
  background: #ffffff;
  border: 0.950019px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 23.7505px 23.7505px #f9fbff;
  border-radius: 21.3754px;
  width: 69%;
  margin-top: 40px;
  @include mq(1100px) {
    width: 100%;
  }
  .first_text {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    color: #000000;
    margin: 50px;
    margin-bottom: 30px;
  }
}

@mixin change_box {
  position: relative;
  margin-bottom: 22px;
  .top {
    border: 0.7px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    padding: 17px 23px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &.hard {
      border: 0.7px solid #173963;
      border-radius: 10px 10px 0px 0px;
    }

    .left {
      .changeText {
        font-weight: 300;
        font-size: 13.3331px;
        line-height: 16px;
        margin-bottom: 10px;
        color: #656565;
      }
      .number {
        font-weight: 600;
        font-size: 22.3331px;
        line-height: 27px;
        color: #525252;
        width: 100%;
        border: none;
        &:focus-visible {
          outline: none;
        }
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
          appearance: none;
        }
      }
    }
  }
}

@mixin white_box {
  background: #ffffff;
  border: 0.518739px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 17.4982px 17.4982px rgba(243, 243, 243, 0.4);
  border-radius: 15.7484px;
  margin-top: 37px;
  padding: 31px 40px;
  width: 80%;
  @include mq(1068px) {
    width: 100%;
  }
}
@mixin set_card {
  position: relative;
  width: 100%;
  padding-bottom: 30px;
}
@mixin main_aspect {
  width: 30%;
  margin: auto;
  margin-top: 50px;
  @include mq(968px) {
    width: 60%;
  }
  @include mq(668px) {
    width: 80%;
  }
  @include mq(468px) {
    width: 90%;
  }
}

@mixin right_nav {
  display: flex;
  align-items: center;
  .toggleSide {
    display: flex;

    .togDiv {
      margin: 0 6px;
      .switch {
        position: relative;
        width: 40px;
        height: 21.05px;
        float: left;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #edf5ff;
          border: 2px solid #b7d7ff;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          &:before {
            position: absolute;
            content: "";
            height: 14px;
            width: 14px;
            left: 4px;
            top: 8%;
            bottom: 4px;
            background-color: #eaac0c;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          /* Rounded sliders */
          &.round {
            border-radius: 34px;
          }

          &.round:before {
            border-radius: 50%;
          }
        }
        // input:checked + .slider {
        //   background-color: #2196F3;
        // }

        input:focus + .slider {
          box-shadow: 0 0 1px #ffffff;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(13px);
          -ms-transform: translateX(13px);
          transform: translateX(13px);
        }
      }
    }
  }
  .cover_drop {
    margin: 0 22px 0 57px;
    .css-toezm8-MuiButtonBase-root-MuiButton-root {
      background: #f5faff;
      border: 1px solid #deedff;
      padding: 7px;
      box-sizing: border-box;
      box-shadow: 0px 10px 30px rgba(23, 57, 99, 0.1);
      border-radius: 15px;
    }
    .name_action {
      font-weight: 400;
      font-size: 12.3893px;
      line-height: 15px;
      text-transform: capitalize;
      color: #173963;
      margin-right: 30px;
    }
  }
  .notification {
    display: flex;
    outline: none;
    border: none;
    background: linear-gradient(136.67deg, #eaac0c 8.34%, #f34018 95.26%);
    mix-blend-mode: normal;
    opacity: 0.8;
    box-shadow: 0px 5px 34px rgba(186, 19, 88, 0.42);
    border-radius: 14px;
    padding: 8.58px 14.39px;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    justify-content: center;
    color: #ffffff;
    img {
      margin-right: 4px;
    }
  }
}
@mixin box_title {
  display: flex;
  justify-content: space-between;
  padding: 23px 33px;
  width: 100%;
  border-bottom: 0.8px solid #eaeaea;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #000000;
  }
  input {
    background: #f5faff;
    border: 0.670663px solid #cbddf3;
    box-sizing: border-box;
    box-shadow: 13.4133px 6.70663px 13.4133px #edf5ff;
    border-radius: 13.4133px;
    width: 109px;
    height: 32px;
    font-weight: 400;
    font-size: 10px;
    padding: 10px 12px;
    line-height: 12px;

    color: #666666;
  }
}
@mixin stat_btn {
  background: #dbebff;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10.4413px;
  line-height: 9px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #173e6f;
  border: none;
  padding: 5px 14px;
  display: block;
  margin-right: 45px;
  width: 85px;
  &.success {
    background: #dfffe4;
    color: #3aa64c;
  }
  &.failed {
    background: #ffd9d0;
    color: #f34018;
  }
}
@mixin cut_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.4em;
  max-height: 2 * 1.2rem;
}

@mixin drop_down {
  background: #ffffff;
  border: 0.7px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 6px;

  margin-top: -5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 340px;
  width: 100%;
  z-index: 5;
  padding: 26px 22px;
  padding-top: 31px;
  position: absolute;

  transform: scale(0);
  transform-origin: top left;
  transition: transform 0.2s ease;
  &.show {
    transform: scale(1);
  }
  p.empty {
    margin: 0;
    color: #f34018;
  }
  .overflow {
    height: 100%;

    width: 100%;
    .cov_ov {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      @include webkit3;
    }
  }
  .own_country,
  .all_country {
    width: 100%;
    .title {
      font-weight: 400;
      font-size: 7.43423px;
      line-height: 9px;
      margin: 0;
      color: #000000;
      margin-bottom: 13px;
    }
  }
}

.trend svg {
  stroke-width: 4;
}
