.profile_details {
  margin-top: 39px;
  margin-left: 41px;
}
.profile_details {
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;

    color: #000000;
  }
}

.profile_details p {
  font-size: 14px;
  color: #5c5c5c;
  margin-bottom: 4px;
}

.profile_info {
  box-shadow: 0px 18px 18px rgba(243, 243, 243, 0.4);
  border-radius: 16px;
  width: 80%;
  height: 475.03px;
  border: 0.5px solid #e5e5e5;
  margin-top: 38px;
}

.Profile_title {
  border-radius: 0px 0px 5px 5px;
  width: 151px;
  height: 31px;
  background: #ecf4ff;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  p {
    font-size: 12px;

    color: rgba(13, 69, 138, 1);
  }
}

.profile_items {
  padding: 40px;
  display: flex;
  align-items: flex-start;
}

.profile_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.profile_img p {
  color: #173963;
  margin: 0;
  margin-top: 14px;
  font-size: 10px;
}

.profile_names {
  display: flex;
  margin-left: 80px;
}

.profile_names span {
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
}

.profile_names p {
  color: #173963;
  font-size: 10px;
}

.all_profile {
  display: flex;
  flex-direction: column;
}

.ngn_flag {
  margin-top: 5px;
  display: flex;
  align-items: center;
  img {
    width: 21px;
    margin-right: 10px;
  }
  p {
    margin: 0 !important;
  }
}

.edit_btn {
  float: right;
  margin-right: 50px;
  background-color: #173963;
  color: white;
  width: 134px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}
