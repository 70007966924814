@use "../../../assets/sass/index" as *;
.pd_convert_page {
  width: 100%;
  .pd_nav_card {
    .cover {
      justify-content: flex-end;
    }
  }
  .main_aspect {
    @include main_aspect;
    .currency_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-weight: 600;
        font-size: 17.36px;
        line-height: 21px;
        color: #0d458a;
      }
      img {
        width: 19px;
        cursor: pointer;
      }
    }
    .card_convert {
      margin-top: 25px;
      background: #ffffff;
      border: 0.842286px solid #eaeaea;
      box-sizing: border-box;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
      border-radius: 14.9161px;
      padding: 50px 34px 42px;
      p.enter {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 29px;
        color: #173963;
      }
      p.available {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 15px;
        color: #3aa64c;
      }
      .cover_tender {
        width: 100%;
        .changeBox {
          @include change_box;
          .conv {
            position: absolute;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            top: 40%;
            left: 45%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #eaac0c;
          }
          .cover_all_top {
            position: relative;
          }
          .top {
            border-radius: 10px;
            margin-bottom: 11px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .ech_cover {
      display: flex;
      align-items: center;
      margin-top: 16px;
      p {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        margin-left: 7px;
        color: #5c5c5c;
      }
    }
  }
}
