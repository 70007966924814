@use "../../../../assets/sass/index" as *;
.pd_each_account_card {
  height: 129px;
  padding: 19px 23px;
  background: #f6f6f6;
  mix-blend-mode: normal;
  border: 1.06051px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 12.8256px;
  width: 100%;
  cursor: pointer;
  //   width: 269px;
  //   margin-right: 18px;
  //   margin-bottom: 18px;
  //   &:last-child {
  //     margin-right: 0;
  //   }
  //   @include mq(768px) {
  //     width: 100%;
  //     // margin-right: 0;
  //   }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
    }
    .flag {
      width: 29px;
    }
  }
  .amount {
    margin-top: 26px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #000000;
  }
}
