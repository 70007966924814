@use "../../../assets/sass/index" as *;

.pd_select_form {
  .center_container {
    @include center_container;
    width: 30%;
    .select_cover {
      width: 100%;
      .each_select {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 48px;
        }
        span {
          margin-right: 40px;
          width: 44px;
        }
        .text_side {
          flex: auto;
          p {
            margin: 0;
          }
          .bold {
            display: flex;

            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            p {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;

              color: #000000;
            }
            p.pending {
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              text-align: right;

              color: #eaac0c;
            }
          }
          .light {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;

            color: #656565;
          }
        }
      }
    }
  }
}
.pd_complete_registration {
  width: 100%;
  position: relative;
  padding-bottom: 50px;
  .pd_custom_flag_drop_down {
    padding: 0 !important;
  }

  .center_container {
    @include center_container;
    width: 40%;
    margin-top: 90px;
    .formHead {
      display: flex;
      margin-bottom: 43px;
      align-items: center;
      img {
      }
      p {
        font-weight: 600;
        font-size: 24.0067px;
        line-height: 29px;

        /* Primary Color */
        margin-left: 22px;
        color: #173963;
      }
    }
    .residential_info {
      margin-top: 46px;
      p {
        font-weight: 600;
        font-size: 14.0067px;
        line-height: 17px;
        /* identical to box height */

        margin: 0;
        /* Primary Color */
        margin-bottom: 23px;

        color: #173963;
      }
    }
    .file_upload {
      margin-top: 35px;
      width: 100%;
      p {
        margin: 0;
      }
      .text_head {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 8.75;
        color: #0d458a;
      }
      .sub_text {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 17.36px;
        color: #5c5c5c;
      }
      .cover_file {
        margin-bottom: 97px;
        label {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5faff;
          border: 1px dashed #0d458a;
          box-sizing: border-box;
          border-radius: 8.42286px;
          cursor: pointer;
          height: 138px;
          p.upload {
            margin: 0;
            text-align: center;
            padding: 16px 57px;
            border: none;
            font-weight: bold;
            background: #ffffff;

            border-radius: 6px;

            color: $primary;
          }
        }
      }
    }
    .command {
      display: flex;
      .eachCommand {
        display: flex;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
        img {
          margin-right: 8px;
        }
        p {
          margin: 0;
          font-weight: 400;
          font-size: 10.3398px;
          line-height: 13px;

          color: #5c5c5c;
          @include mq(600px) {
            font-size: 8px;
          }
          @include mq(380px) {
            font-size: 7px;
          }
          @include mq(350px) {
            font-size: 6px;
          }
        }
      }
    }
  }
}
