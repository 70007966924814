@use "../../../../../assets/sass/index" as *;

.pd_recipient_card {
  background: #f8f8f8;
  border-radius: 10.6893px;

  padding: 13px 16px;

  display: flex;
  align-items: center;
  margin-bottom: 15px;

  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .cover_img {
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 12px 11px;
    margin-right: 17px;
    img {
      width: 21px;
    }
  }
  .text_side {
    display: flex;
    align-items: center;

    .top {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
    .dot {
      margin: 0 5px;
      background-color: #000000;
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }
    .bottom {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #0d458a !important;
    }
  }
}
