@use "../../assets/sass/index" as *;
.header {
  position: relative;
  height: 570px;
  .empty_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    div {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .slick-slider {
    z-index: 2;
  }
  .cover_heading {
    display: flex;
    justify-content: space-between;
    padding: 35px 70px;
    position: relative;
  }
  @include mq(1100px) {
    padding: 35px 50px;
  }
  @include mq(900px) {
    flex-direction: column;
  }
  .show_page {
    position: absolute;
    bottom: 50px;
    left: 100px;
    z-index: 3;
    cursor: pointer;
    .pause_cover {
      width: 50px;
      height: 50px;
      border: 1px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      .css-i4bv87-MuiSvgIcon-root {
        fill: white;
      }
    }
    p {
      color: white;
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
    }
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .pause {
    display: block;
    background: none;
    border: none;
    .circular {
      height: 100px;
      width: 100px;
      position: relative;
      border: solid green 1px;
      .inner {
        position: absolute;
        z-index: 6;
        top: 50%;
        left: 50%;
        height: 80px;
        width: 80px;
        margin: -40px 0 0 -40px;
        background: red;
        border-radius: 100%;
      }
      .number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        font-size: 18px;
        font-weight: 500;
        color: #4158d0;
      }
      .right {
        transform: rotate(180deg);
        z-index: 3;
        .progress {
          animation: right 4s linear both;
          animation-delay: 4s;
        }
        @keyframes right {
          100% {
            transform: rotate(180deg);
          }
        }
      }
      .bar {
        position: absolute;
        height: 100%;
        width: 100%;
        background: #fff;
        -webkit-border-radius: 100%;
        clip: rect(0px, 100px, 100px, 50px);
        .progress {
          position: absolute;
          height: 100%;
          width: 100%;
          -webkit-border-radius: 100%;
          clip: rect(0px, 50px, 100px, 0px);
          background: #4158d0;
          z-index: 1;
          animation: left 4s linear both;
        }
        @keyframes left {
          100% {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .header-form {
    width: 27%;
    position: absolute;
    z-index: 3;
    right: 94px;
    top: 35px;
    @include mq(1100px) {
      width: 35%;
      display: none;
    }
    @include mq(900px) {
      margin-top: 20px;
      width: 100%;
    }
    .convBox {
      background: #ffffff;
      border-radius: 20px;
      z-index: 2;
      padding: 26px 36px;
      width: 100%;

      .title {
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        margin-bottom: 16px;
      }
      .changeBox {
        position: relative;
        .conv {
          position: absolute;
          width: 33px;
          height: 33px;
          border-radius: 50%;
          top: 40%;
          left: 45%;
          z-index: 3;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eaac0c;
        }
        .top {
          border: 0.7px solid #c4c4c4;
          box-sizing: border-box;
          border-radius: 0px 0px 10px 10px;
          padding: 17px 23px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.hard {
            border: 0.7px solid #173963;
            border-radius: 10px 10px 0px 0px;
          }
          // .pd_convert_flag_drop_down {
          //   position: unset;
          //   .drop_down {
          //     width: 100%;
          //     left: 0;
          //     top: 0;
          //     right: unset;
          //   }
          // }
          .right {
            //   background: #f5faff;
            //   border-radius: 6.55855px;
            //   padding: 13px 9.8px;
            //   width: 110px;
          }
          .left {
            .changeText {
              font-weight: 300;
              font-size: 13.3331px;
              line-height: 16px;
              margin-bottom: 10px;
              color: #656565;
            }
            .number {
              font-weight: 600;
              font-size: 22.3331px;
              line-height: 27px;
              color: #b3b3b3;
            }
          }
        }
      }
      .pro {
        width: 100%;
        margin-top: 21px;
        .eachPro {
          display: flex;
          width: 100%;
          margin-bottom: 12px;
          align-items: center;
          justify-content: space-between;
          .left,
          .right {
            display: flex;
            align-items: center;
            img {
              margin-right: 3px;
            }
            p {
              font-style: normal;
              font-weight: 400;
              font-size: 10.043px;
              line-height: 12px;

              color: #5c5c5c;
            }
          }
          .right {
            p {
              font-weight: 600;
              font-size: 10.5748px;
              line-height: 13px;
              text-align: right;

              color: #000000;
              &.blue {
                color: #0d458a;
              }
            }
          }
        }
      }
    }
  }
}
