@use "../../assets/sass/index" as *;

.pd_each_news {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 14.3409px;
  padding: 18px 20px;
  .top_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;
    img {
      width: 34px;
    }
    p {
      font-weight: 300;
      font-size: 10.6215px;
      line-height: 23px;
      /* or 216% */

      text-align: right;
      letter-spacing: -0.02em;

      color: #828282;
    }
  }
  .title {
    font-weight: 600;
    margin-bottom: 29px;
    font-size: 15.2969px;
    line-height: 23px;
    /* or 150% */

    color: #000000;
  }
  .img_container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 148px;
    width: 100%;
    margin-bottom: 23px;
    border-radius: 15px;
  }
  .read {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 14.3409px;
    width: 100%;
    padding: 13px 0;
    background: none;
    font-weight: 500;
    font-size: 15.2969px;
    line-height: 19px;
    display: block;
    font-family: sqMarketMedium;
    text-align: center;
    color: #272b31;
    text-decoration: none;
  }
}

.pd_each_news:hover > .read {
  background-color: #10315A;
  color: #fff;
}
