@use "../../assets/sass/index" as *;

.listConversion {
  width: 100%;
  // padding: 8px 0;
  padding: 14px 0;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  background-color: #002149;
  .overlay::before,
  .overlay::after {
    background: none;
  }
  .eachConversion {
    display: flex;
    align-items: center;
    margin-right: 24px;

    .name {
      font-weight: 700;
      font-size: 13.6115px;
      line-height: 17px;
      /* identical to box height */

      color: #ffffff;
    }
    img {
      margin: 0 5px;
    }
    .percent {
      font-weight: 500;
      font-size: 13.6115px;
      line-height: 17px;
      &.green {
        color: #34e36f;
      }
      &.red {
        color: #f34018;
      }
    }
  }
  @include webkit;
}
