@use "assets/sass/index" as *;
.pd_select_recipient {
  .main_aspect {
    @include main_aspect;
    margin-top: 68px;
    width: 30%;
    p.choose {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      margin: 43px 0;
      color: #666666;
    }
    .no_recipient_div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        text-align: center;
        margin-top: 7.8px;

        color: #0d458a;
      }
    }
  }
  .cover_rec_form {
    width: 100%;
    p.instruction {
      font-weight: 400;
      font-size: 11.6407px;
      line-height: 14px;
      margin: 9px 0 50px;
      color: #5c5c5c;
    }
  }
}
