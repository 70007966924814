.blog {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.blog img {
  width: 100%;
  height: 250px;
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}

.blog .blog-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px;
}

.blog-content h5 {
  /* font-family: 'SQ Market'; */
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  width: 95%;
  margin-bottom: 2.5rem;
}

.blog-content .blog-topic span {
  /* font-family: 'SQ Market'; */
  font-weight: 400;
  font-size: 12.0672px;
  line-height: 11px;
  color: #000000;
}
