@use "../../../../../assets/sass/index" as *;
.pd_recipient_form {
  input,
  select,
  .select-mui {
    background: white !important;
  }
  label {
    font-size: 12px;
  }
  .attestation_side {
    margin: 28px 0 33px;
    p.title {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      color: #0d458a;
      margin-bottom: 12px;
    }
    p.desc {
      font-weight: 300;
      font-size: 11px;
      line-height: 18px;
      /* or 164% */

      color: #000000;
    }
  }
  .check_container {
    @include check;
  }
}
