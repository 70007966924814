@use "../../../../../../assets/sass/index" as *;

.pd_signup_modal {
  width: 100%;
  .input-icon-wrap {
    background-color: white !important;
  }
  form {
    width: 100%;
    padding: 0;

    .flex {
      width: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 41px;
      @include mq(400px) {
        flex-direction: column;
      }
      label {
        background: #f5faff;
        display: block;
        padding: 27px 16px;
        border-radius: 7.63301px;
        width: 48%;

        @include mq(400px) {
          width: 90%;
          margin-bottom: 15px;
        }
        &.active {
          border: 1.14495px solid #3aa64c;
          box-sizing: border-box;

          background: white;
        }
        .top {
          background: #ffffff;
          box-shadow: 0px 2.69976px 33.7471px rgba(0, 0, 0, 0.05);
          border-radius: 6.74941px;
          padding: 14px;
          width: max-content;
          img {
            width: 39px;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 32px;
          p {
            font-weight: 400;
            font-size: 12.0618px;
            line-height: 15px;
            margin: 0;
            color: #000000;
          }
        }
      }
    }
  }
  .pd_second_step {
    .form-group {
      margin-bottom: 22px;
    }
    p.charge {
      font-weight: 400;
      font-size: 10px;
      line-height: 19px;
      /* or 190% */

      color: #525252;
    }
    .cov {
      margin-bottom: 22px;
      label {
        color: #0d458a !important;
        margin-bottom: 8px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }
      .pd_convert_flag_drop_down {
        &.ice {
          padding: 0 1rem;
          height: 48px;
          align-items: center;
          justify-content: center;
          display: flex;
          .showCurrency {
            .arrdown {
              right: 0;
            }
          }
        }
      }
    }
  }
  .pd_third_step {
    p.set {
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
    .cover_all_otp {
      width: 70%;
      margin-bottom: 140px;
      @include mq(768px) {
        width: 100%;
      }
      .otp_wrap {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

p.sec_text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: #0d458a;
  margin: 44px 0 55px 0;

  &.not_center {
    text-align: left;
  }
  @include mq(400px) {
    font-size: 13px;
  }
}
