@use "../../../../../assets/sass/index" as *;
.pd_set_card {
  @include set_card;
  .main_aspect {
    @include main_aspect;
    .first_text {
      text-align: left;
      margin-bottom: 30px;
    }
  }
}
