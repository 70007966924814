@use "../../assets/sass/index" as *;
.footNote {
  padding: 44px 174px 44px 122px;
  background-color: #10315A;
  @include mq(1070px) {
    padding: 44px 100px;
  }
  #footNote_title {
    font-family: sqMarketMedium;
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 7px;
    color: #ffffff;
  }
  .sub-title {
    font-weight: 100;
    opacity: 0.9;
    font-size: 14.7045px;
    line-height: 18px;
    color: #ffffff;
  }
  .viewFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btnDiv {
      margin: 39px 0;
      margin-bottom: 24px;
      display: flex;
      button {
        border: 1px solid #c5cfdc;
        box-sizing: border-box;
        border-radius: 112.23px;
        padding: 10px 15px;
        color: #c5cfdc;
        margin-right: 22px;
        background: transparent;
        &:last-child {
          margin-right: 0;
        }
        &.yellow {
          background: #eaac0c;
          color: white;
          border: none;
        }
      }
    }
    a {
      font-weight: 300;
      font-size: 14.7045px;
      line-height: 18px;
      text-align: right;

      color: #ffffff;
      text-decoration: none;
    }
  }
  .boxShow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include mq(900px) {
      flex-direction: column;
    }
    .eachBox {
      background: #1c4170;
      border-radius: 12.2903px;
      padding: 25px;
      width: 31%;
      //   max-width: 350px;
      @include mq(900px) {
        width: 100%;
        margin-bottom: 20px;
        max-width: unset;
      }

      .flag {
        display: flex;
        align-items: center;
        color: white;
        margin-bottom: 30px;
        img {
          margin-right: 7px;
        }
        p {
          font-weight: 400;
          font-size: 13.3853px;
          line-height: 16px;
          color: #ffffff;
        }
      }
      .rate {
        font-size: 31.1352px;
        line-height: 38px;
        color: #ffffff;
        .cur {
          font-weight: 400;
        }
        .money {
          font-weight: 600;
          margin-left: 10px;
        }
      }
      .arrow {
        display: flex;
        align-items: center;
        margin-top: 9px;
        margin-bottom: 30px;
        p {
          font-weight: 500;
          font-size: 15.7202px;
          line-height: 19px;
          &.green {
            color: #34e36f;
          }
          &.red {
            color: #f34018;
          }
        }
      }
      .track {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-weight: 400;
          font-size: 10.6982px;
          line-height: 13px;
          color: #84a8d3;
        }
        a {
          font-weight: 400;
          font-size: 17.0176px;
          line-height: 21px;
          text-decoration: none;
          color: #eaac0c;
        }
      }
    }
  }
}
