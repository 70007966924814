@use "../../assets/sass/index" as *;

.pd_signup_modal {
  width: 100%;

  form {
    width: 100%;
    padding: 0 45px;
    .flex {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 41px;
      @include mq(400px) {
        flex-direction: column;
      }
      label {
        background: #f5faff;
        display: block;
        padding: 27px 16px;
        border-radius: 7.63301px;
        width: 48%;

        @include mq(400px) {
          width: 90%;
          margin-bottom: 15px;
        }
        &.active {
          border: 1.14495px solid #3aa64c;
          box-sizing: border-box;

          background: white;
        }
        .top {
          background: #ffffff;
          box-shadow: 0px 2.69976px 33.7471px rgba(0, 0, 0, 0.05);
          border-radius: 6.74941px;
          padding: 14px;
          width: max-content;
          img {
            width: 39px;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 32px;
          p {
            font-weight: 400;
            font-size: 12.0618px;
            line-height: 15px;
            margin: 0;
            color: #000000;
          }
        }
      }
    }
  }
}
p.first_text {
  font-weight: 600;
  font-size: 20.9237px;
  line-height: 26px;
  text-align: center;
  margin: 0;
  color: #0d458a;
  &.not_center {
    text-align: left;
  }
  @include mq(400px) {
    font-size: 18px;
  }
}
p.sec_text {
  font-weight: 400;
  font-size: 15.0881px;
  line-height: 18px;
  text-align: center;
  margin: 11.57px 0 49.75px 0;
  color: #173963;
  &.not_center {
    text-align: left;
  }
  @include mq(400px) {
    font-size: 13px;
  }
}
