@use "../../../../assets/sass/index" as *;

.pd_track_transaction {
  width: 100%;

  .head {
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .left {
      display: flex;
      align-items: center;

      .arrow {
        margin: 0 3px;
      }
    }

    // button {
    //   &.success {
    //     color: #3aa64c;
    //     background: #dfffe4;
    //     border-radius: 4.92207px;
    //   }
    // }
  }

  .money {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #173e6f;
  }

  .details {
    width: 100%;
    padding: 14px 20px;
    background: #f5faff;
    border-radius: 10px;
    margin: 18px 0 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      .bank_name {
        font-weight: 600;
        margin-bottom: 12px;
        font-size: 11.1473px;
        line-height: 14px;
        color: #000000;
      }

      .name {
        font-weight: 400;
        font-size: 12.1473px;
        line-height: 15px;
        color: #000000;
      }
    }

    .right {
      display: flex;
      align-items: center;

      p {
        font-weight: 600;
        font-size: 9.33307px;
        line-height: 11px;
        text-align: right;
        margin-left: 5px;
        color: #0d458a;
      }
    }
  }

  .last {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;

    button {
      background: none;
      border: none;
      outline: none;
      font-weight: 600;
      font-size: 15px;
      cursor: pointer;
      line-height: 18px;
      text-align: center;

      &.share {
        color: #3aa64c;
      }

      &.close {
        color: #173e6f;
      }
    }
  }
}

.money_box {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;

  .first_box {
    .top {
      font-weight: 300;
      font-size: 12.3893px;
      line-height: 15px;
      margin-bottom: 12px;
      color: #5c5c5c;
    }

    .flex_currency {
      display: flex;

      .pd_convert_flag_drop_down {
        .drop_down {
          &.show {
            right: unset;
            left: 0;
          }
        }
      }

      img {
        margin-left: 5px;
      }
    }
  }

  .sec_box {
    .port {
      font-weight: 400;
      font-size: 9.7333px;
      line-height: 12px;
      text-align: center;
      margin-bottom: 5px;
      color: #000000;
    }

    .money {
      font-weight: 700;
      font-size: 34.7261px;
      line-height: 42px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #000000;
    }

    .green {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      img {
        margin-right: 3px;
      }

      p {
        font-weight: 500;
        font-size: 11.4395px;
        line-height: 14px;

        color: #3aa64c;
      }
    }
  }

  p.date {
    font-weight: 400;
    font-size: 11.3433px;
    line-height: 14px;
    text-align: right;
    color: #173e6f;
  }

  @include mq(600px) {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;

    .sec_box {
      margin: 15px 0;

      .port,
      .money {
        text-align: left;
      }

      .green {
        justify-content: flex-start;
      }
    }

    p.date {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}

.account_side {
  margin-top: 44px;
  width: 98%;

  .cover_first_text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;

    a {
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      color: #173e6f;
    }
  }

  .first_text {
    font-size: 13px;
    text-align: left;
  }

  .cover_flex {
    width: 100%;

    &.overflow {
      overflow-x: auto;

      @include webkit4;
    }
  }

  .account_flex {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &.over {
      width: max-content;
      flex-direction: row !important;
      justify-content: unset;
    }

    .create_box {
      text-decoration: none;
      color: inherit;
      display: flex;
      height: 103px;
      width: 30%;
      align-items: center;
      justify-content: center;
      mix-blend-mode: normal;
      border: 1.10774px dashed #aaaaaa;
      box-sizing: border-box;
      border-radius: 11.0774px;
      gap: 4px;
    }

    @include mq(600px) {
      flex-direction: column;
      align-items: unset;
      justify-content: unset;

      .pd_each_account,
      .create_box {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

.send_money {
  @include change_box;
}
