@use "../../assets/sass/index" as *;
.pd_auth_template {
  width: 100%;
  display: flex;
  min-height: 100vh;
  .left_container {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    .center_container {
      width: 40%;
      margin: auto;
      margin-top: 100px;
      @include mq(968px) {
        width: 60%;
      }
      @include mq(568px) {
        width: 80%;
      }
    }
    @include mq(768px) {
      width: 100%;
    }
  }
  .right_container {
    position: sticky;
    top: 0;
    width: 35%;
    right: 0;
    background-image: url("../../assets/drawables/welcome_man.jpeg");
    background-position: center;
    background-size: cover;
    height: 100vh;
    background-color: $primary;
    .overlay {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: rgba($color: $primary, $alpha: 0.4);

      p {
        margin: 0;
        font-weight: 600;
        font-size: 29.8129px;
        line-height: 35px;

        margin-top: 500px;
        /* or 118% */
        text-align: center;
        color: #ffffff;
      }
    }
    @include mq(768px) {
      display: none;
    }
  }
}
