@use "assets/sass/index" as *;
.pd_summary {
  .main_aspect {
    @include main_aspect;
    margin-top: 68px;
    width: 30%;
    .first_text_send {
      margin-bottom: 10px;
    }
    p.sub {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 27px;
      color: #000000;
    }
    .summary_side {
      background: #ffffff;
      border: 0.7px solid #c9c9c9;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 29px 41px;
      .convert {
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        justify-content: center;
        .arrow {
          margin: 0 5px;
        }
      }
      .desc_box {
        width: 100%;
        .each_desc {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 17px;
          &:last-child {
            margin-bottom: 0;
          }
          .faint {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;

            color: #000000;
          }
          .bold {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
          }
        }
      }
      .recipient_side {
        background: #f5faff;
        border-radius: 10px;
        padding: 18px;
        margin: 26px 0 29px;
        p.name {
          font-weight: 400;
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.1em;
          margin-bottom: 11px;
          color: #0d458a;
        }
        p.bank {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          /* identical to box height */

          margin-bottom: 5px;
          color: #000000;
        }
        .name_box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .acc_name {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;

            color: #000000;
          }
          .type_side {
            display: flex;
            align-items: center;
            p {
              font-size: 9px;
              line-height: 11px;
              color: $primary;
              font-weight: 600;
              margin-right: 3px;
            }
          }
        }
      }
      .total_money {
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 27px;
        text-align: center;
        color: #0d458a;
      }
      .cancel {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-decoration-line: underline;
        background: none;
        border: none;
        display: block;
        margin: auto;
        margin-top: 20px;
        color: #173963;
      }
    }
  }
}
