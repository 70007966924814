@use "../../assets/sass/index" as *;

.drop-down-wrapper {
  position: relative;
  .drop-down-content {
    width: fit-content;
    position: absolute;
    right: 0%;
    top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.5rem;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08),
      0px 0px 1px rgba(50, 50, 71, 0.2);
    border-radius: 7px;
    background-color: white;
    transform: scale(0);
    transform-origin: top left;
    transition: transform 0.2s ease;
    width: max-content;
    min-width: 150px;
    z-index: 10;
    &.show-drop-down {
      transform: scale(1);
    }
    .hr {
      width: 100%;
      border: 1px solid #edf2f7;
    }
    > button,
    > a {
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      margin-top: 0rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      &:first-child {
        margin-top: 0;
      }
      &:hover {
        background: none !important;
      }
    }
  }
}
