.getstarted {
  background: #3aa64c;
  border-radius: 10px;
   font-family: sqMarketMedium;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 20px 35px;
}
