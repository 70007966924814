@use "../../../../assets/sass/index" as *;

.pd_card_ice {
  .emptyaccount {
    margin: auto;
    width: max-content;
    p {
      font-weight: 300;
      font-size: 16.1554px;
      line-height: 20px;
      /* identical to box height */

      text-align: center;

      color: #000000;
    }
  }
}
