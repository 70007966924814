.referral {
  display: flex;
  /* padding: 50px; */
  padding: 4.5rem 10rem;
  gap: 4rem;
}

.referral-left {
  flex: 1;
  /* padding: 30px; */
}

.referral-right {
  flex: 1.5;
  /* padding: 20px; */
}
