@use "../../assets/sass/index" as *;
.support {
  background: #ffffff;
  border: 0.4px solid #edf5ff;
  box-sizing: border-box;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: fixed;
  padding: 10px 16px;
  // top: 600px;
  right: 82px;
  bottom: 37px;
  &.down {
    bottom: 37px;
    top: unset;
  }
  img {
    margin-right: 3px;
  }
  font-weight: 500;
  font-size: 12.3893px;
  line-height: 15px;

  color: #0d458a;
  @include mq(868px) {
    display: none;
  }
}
