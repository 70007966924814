@use "../../assets/sass/index" as *;

.pd_each_account {
  width: 30%;
  padding: 13px 17px;
  background: #f6f6f6;
  mix-blend-mode: normal;
  border: 0.850679px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 10.2879px;
  height: 103px;
  padding-bottom: 17px;
  .top_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    p {
      font-weight: 600;
      font-size: 12.4031px;
      line-height: 15px;
      text-align: right;
      color: #000000;
    }
  }
  .amount {
    font-weight: 700;
    font-size: 23.7261px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #000000;
  }
  .flag {
    width: 29px;
  }
}
