@use "../../assets//sass/index" as *;

.pd_page_description {
  // background: #173e6f;
  padding: 33px 60px;
  padding-right: 0;
  padding-bottom: 0;
  @include mq(768px) {
    padding-left: 40px;
  }
  .page_description_market_title {
    font-family: sqMarketMedium;
    font-size: 34px;
    line-height: 41px;
    color: #173E6F;
  }
  .description {
    font-weight: 300;
    font-size: 12.0679px;
    line-height: 15px;
    margin-top: 6px;
    color: #173E6F;
  }
  .date {
    background: #edf5ff;
    border-radius: 99.137px;

    padding: 6.75px 8.41px;
    width: max-content;
    margin-top: 11px;
    font-weight: 400;
    font-size: 8.83458px;
    line-height: 11px;
    text-align: center;

    color: #173e6f;
  }
  @include mq(600px) {
    .page_description_market_title {
      font-size: 21px;
    }
    .description {
      font-size: 11px;
    }
  }
}
