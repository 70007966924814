.investment-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.investment-content h4 {
   font-family: sqMarketMedium;
  font-weight: 500;
  /* font-size: 28px; */
  font-size: 28px;
  /* line-height: 150%; */
  color: #ffffff;
  margin-bottom: 25px;
  width: 100%;
}

.investment-content p {
  /* font-family: 'SQ Market'; */
  font-weight: 400;
  font-size: 15.55px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #e0e0e0;
  margin-bottom: 3.7rem;
}
