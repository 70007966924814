@font-face {
  font-family: myFirstFont;
  src: url("./assets/fonts/SQ\ Market\ Regular\ Regular.ttf");
}

@font-face {
  font-family: sqMarketBold;
  src: url("./assets/fonts/SQ\ Market\ Bold\ Bold.ttf");
}

@font-face {
  font-family: sqMarketMedium;
  src: url("./assets/fonts/SQ\ Market\ Medium.otf");
}
.bolden {
  font-family: sqMarketBold !important;
}
/* .bold,
.title,
.first_text {
  font-family: sqMarketBold !important;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: myFirstFont;
}
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .section__padding {
  padding: 2rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
} */
