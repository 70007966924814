.header {
  /* background-image: url("../../assets/drawables/heroimg.png"); */

  /* height: 70vh; */

  /* align-items: flex-start; */
}
.swiper-wrapper {
  transition-duration: 1500ms !important;
}
.header-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: sqMarketBold;
}


.header-text h2 {
  font-weight: 700;
  font-size: 46.7174px;
  line-height: 55px;
  color: #ffffff;
  word-break: break-all;
}

.header-form {
  /* margin: 20px 0; */
}
