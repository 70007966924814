@use "../../../../assets/sass/index" as *;
.pd_create_account {
  .create_main {
    width: 30%;
    margin: auto;
    margin-top: 60px;
    @include mq(900px) {
      width: 40%;
    }
    @include mq(700px) {
      width: 60%;
    }
    @include mq(500px) {
      width: 80%;
    }
    @include mq(400px) {
      width: 90%;
    }
  }
}
