@use "../../../../assets/sass/index" as *;

.pd_each_history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  &:last-child {
    margin-bottom: 0;
  }
  .stat_part {
    display: flex;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
    }
    .cover_box {
      width: 33px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      margin-right: 13px;
      background: #f2f8ff;
    }
  }
  .amount {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #173e6f;
  }
  .balance {
    font-weight: 300;
    font-size: 12px;
    line-height: 10px;

    text-align: right;

    color: #000000;
  }
}
