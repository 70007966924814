@use "../../assets/sass/index" as *;
.pd_atm_card {
  background: #f5faff;
  border-radius: 13.6793px;
  padding: 12px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
  &:first-child {
    margin-top: 46px;
  }

  &.active {
    background: #ffffff;
    border: 0.772447px solid #3aa64c;
  }
  &.expired {
    background: #fff5f2;
    border: 0.772447px solid #ffd4cb;
  }
  .left_part {
    .top_part {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      p {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        color: #545454;
        margin-left: 7px;
      }
    }
    p.number {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #545454;
      margin-bottom: 6px;
    }
    p.exp {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;

      color: #545454;
    }
  }
  .right_part {
    img {
      width: 18px;
    }
  }
}
