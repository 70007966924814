.card_payment_form {
	.pay_link {
		font-weight: 600;
		font-size: 12px;
		margin: 30px 0;
		display: block;

		line-height: 16px;
		/* identical to box height */

		text-align: center;
		text-decoration-line: underline;

		color: #0d458a;
	}
}