@use "../../../../../assets/sass/index" as *;

.pd_highlight {
  background: #f7f7f7;
  border-radius: 10.003px;
  margin-right: 22px;
  width: 280px;
  padding: 20px;
  &:last-child {
    margin-right: 0;
  }
  .top_part {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    img {
      width: 16px;
      margin-right: 6px;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
    }
  }
  .money_part {
    display: flex;
    margin-bottom: 5px;
    .code,
    .money {
      font-weight: 400;
      font-size: 26px;
      line-height: 31px;
      color: #000000;
    }
    .money {
      font-weight: 600;
      margin-left: 3px;
    }
  }
  .bottom_part {
    margin-top: 26px;
    display: flex;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 8px;
      line-height: 11px;
      color: #525252;
    }
    a {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #eaac0c;
      display: flex;
      text-decoration: none;
      align-items: center;
      img {
        transform: rotate(180deg);
        margin-left: 3px;
      }
    }
  }
}
