@use "../../../../../assets/sass/index" as *;

.pd_each_security {
  width: 270px;
  background: #f6f6f6;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 14.5776px;
  padding: 20px 0;
  margin-right: 26px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  .top_part {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 27px;
    justify-content: space-between;
    padding-bottom: 0;
    .left_top {
      .img_box {
        display: flex;
        img {
          margin-right: 5px;
        }
        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          color: #000000;
        }
      }
      .name {
        font-weight: 300;
        font-size: 10.5224px;
        line-height: 13px;
        margin-top: 9px;
        color: #828282;
      }
    }
    .right_top {
      display: flex;
      align-items: center;
      p {
        font-weight: 400;
        font-size: 11.7514px;
        line-height: 14px;
        text-align: right;
        color: #0d458a;
      }
    }
  }
  .money_side {
    margin-bottom: 20px;
    padding: 0 20px;
    p.money {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -0.04em;
      margin-bottom: 5px;
      color: #000000;
    }
  }
  .trend {
    width: 100%;
  }
  .bottom_part {
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    .rate {
      display: flex;
      align-items: center;
      p {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        color: #5c5c5c;
        &:last-child {
          font-weight: 600;
          font-size: 10.1818px;
          line-height: 12px;
          color: #5c5c5c;
        }
      }
      .small_circle {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #34e36f;
        margin: 0 7px;
      }
    }
    .net {
      display: flex;
      align-items: center;
      p {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        margin-right: 3px;
        color: #5c5c5c;
      }
    }
  }
}
