@use "../../../assets/sass/index" as *;

.pd_market_dashboard {
  .cover_all {
    padding: 4px 60px 40px !important;
    @include mq(600px) {
      padding: 4px 20px 40px !important;
    }
  }
}
