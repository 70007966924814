@use "../../../../assets/sass/index" as *;
.pd_set_card {
  @include set_card;
  .main_aspect {
    @include main_aspect;
    .first_text {
      text-align: left;
      margin-bottom: 30px;
    }
    .check_container {
      @include check;
    }
    .pay_link {
      font-weight: 600;
      font-size: 12px;
      margin: 30px 0;
      display: block;

      line-height: 16px;
      /* identical to box height */

      text-align: center;
      text-decoration-line: underline;

      color: #0d458a;
    }
    .secure_box {
      text-align: center;
      p {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        /* identical to box height */

        color: #b3b3b3;
      }
    }
  }
}
