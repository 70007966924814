@use "../../../../../assets/sass/index" as *;
.pd_set_bank {
  @include set_card;
  .main_aspect {
    @include main_aspect;

    .type_box {
      border: 0.842286px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 14.9161px;
      padding: 48px 35px;
    }
  }
  &.send_ice {
    .cover_house {
      width: 54px;
      height: 48px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      img {
        width: 25px;
      }
    }
    p.balance {
      font-weight: 400;
      font-size: 11.9005px;
      line-height: 15px;
      text-align: center;

      color: #000000;
      margin: 37px 0 9px 0;
    }
    p.amount {
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 59px;
      color: #0d458a;
    }
  }
}
