@use "../../../../../assets/sass/index" as *;
.pd_about_bank_card {
  background-color: rgba($color: #e5e5e5, $alpha: 0.1);
  min-height: 100vh;

  .main_aspect {
    padding: 36px 51px;
    width: 100%;
    .cover_all_card {
      display: flex;
      margin-top: 27px;
      justify-content: space-between;
      align-items: flex-start;
      @include mq(900px) {
        flex-direction: column;
      }
      .left_card {
        width: 25%;
        p.your {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 15px;
          color: #000000;
        }
        .action_flex {
          width: 100%;
          display: flex;
          margin-top: 19px;
          align-items: center;
          justify-content: space-between;
          position: relative;
          .drop_manage {
            position: absolute;
            width: 172px;
            background: #ffffff;
            border: 0.8px solid #f4f4f4;
            box-sizing: border-box;
            box-shadow: 0px 20px 20px #f9fbff;
            border-radius: 15px;
            padding: 24px 26px;
            right: 0;
            top: 20px;
            button {
              background: none;
              margin-bottom: 10px;
              border: none;
              color: #000000;

              &:last-child {
                margin-bottom: 0;
              }
              &.red {
                color: #f34018;
              }
            }
            transform: scale(0);
            transform-origin: top left;
            transition: transform 0.2s ease;
            &.show {
              transform: scale(1);
            }
          }
          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            cursor: pointer;
            color: #000000;
          }
        }
        .pd_card_detail {
          background: #ffffff;
          width: 100%;
          border: 0.8px solid #f4f4f4;
          box-sizing: border-box;
          box-shadow: 0px 20px 20px #f9fbff;
          border-radius: 18px;
          margin-top: 30px;
          .head_side {
            padding: 22px;
            border-bottom: 0.8px solid #eaeaea;
            p {
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
            }
          }
          .det_side {
            padding: 19px 22px;
            .each_det {
              margin-bottom: 36px;
              &:last-child {
                margin-bottom: 0;
              }
              p {
                font-weight: 400;
                font-size: 11.45px;
                line-height: 14px;
                color: #0d458a;
                &.black {
                  color: #000000;
                  margin-top: 5px;
                }
              }
            }
            .each_det_flex {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 36px;
              .each_det {
                margin-bottom: 0 !important;
              }
            }
          }
        }
        @include mq(900px) {
          width: 100%;
        }
      }
      .right_card {
        width: 60%;
        background: #ffffff;
        border: 0.8px solid #f4f4f4;
        box-sizing: border-box;
        box-shadow: 0px 20px 20px #f9fbff;
        border-radius: 18px;
        .box_title {
          @include box_title;
        }
        .stat_btn {
          @include stat_btn;
        }
        .full_history {
          padding: 22px 48px;
          p.day {
            font-weight: 300;
            font-size: 12px;
            line-height: 10px;
            color: #818181;
            margin-bottom: 25px;
          }
        }
        @include mq(900px) {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .pd_enter_pin_box {
    &.changepin {
      .cover_all_otp {
        margin-bottom: 30px;
        .otp_wrap {
          margin-bottom: 23px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        label {
          margin: 0;
          margin-bottom: 12px;
        }
      }
    }
    .small_text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-top: 34px;
      margin-bottom: 40px;
      color: #000000;
    }
    .cover_all_otp {
      margin-bottom: 90px;
      .otp_wrap {
        width: 70%;
        @include mq(900px) {
          // width: 100%;
        }
      }
    }
    label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height */

      color: #000000 !important;
      margin: 48px 0 27px;
    }
  }
  .pd_action_card {
    .ask {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height */

      margin-top: 55px;
      margin-bottom: 18px;
      text-align: center;
      color: #000000;
    }
    img {
      width: 215px;
      margin: auto;
      display: block;
    }
    button {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      border: none;
      background: none;
      display: block;
      color: #173e6f;

      margin: 30px auto 0 auto;
      &.red {
        color: #f34018;
      }
    }
  }
}
