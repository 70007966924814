@use "../../../assets/sass/index" as *;

.App-header {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 0;
  position: relative;

  .logo a {
    color: $primary;
    text-decoration: none;
    &:visited {
      text-decoration: none;
    }
  }

  .ReactFlagsSelect-module_selectBtn__19wW7 {
    font-family: $font-reg !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #000000;
    line-height: 18px !important;
    width: 100px !important;
  }

  .ReactFlagsSelect-module_label__27pw9,
  .ReactFlagsSelect-module_secondaryLabel__37t1D {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    font-family: $font-reg;
  }

  nav {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mq(900px) {
      @include mob_nav;
      &.nav-opened {
        clip-path: inset(0 0 0 0);
        padding: 20px;
        padding-bottom: 120px;
        .navList {
          align-items: unset;
          flex-direction: column;
          margin: 0;
          margin-top: 50px;
          li {
            margin: 0;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .selectLang {
          margin: 30px 0;
        }
        .btnDiv {
          margin-bottom: 40px;
        }
      }
    }

    .user-types {
      display: flex;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #000000;

      .dot {
        margin: 0 22px;
      }

      .link {
        text-decoration: none;
        color: inherit;
        &.active {
          font-family: 'sqMarketMedium';
        }
      }
    }
    .navList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 0 49px 0 160px;
      li {
        list-style-type: none;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        cursor: pointer;
        text-align: center;
        color: #000000;
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
      width: 160px;
      padding-bottom: 0;
      .ReactFlagsSelect-module_selectBtn__19wW7 {
        border: none;
        outline: none;
      }
    }
    .btnDiv {
      display: flex;
      align-items: center;
      button {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
      }
      .login {
        font-family: sqMarketMedium;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #173963;
        margin-right: 22px;
      }
      .signup {
        background: #3aa64c;
        border-radius: 10px;
        font-family: sqMarketMedium;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        width: 117px;
        height: 47px;
      }
    }
    .toggleSide {
      display: flex;
      margin-left: 53px;
      .togDiv {
        margin: 0 6px;
        .switch {
          position: relative;
          width: 40px;
          height: 21.05px;
          float: left;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #edf5ff;
            border: 2px solid #b7d7ff;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &:before {
              position: absolute;
              content: "";
              height: 14px;
              width: 14px;
              left: 4px;
              top: 8%;
              bottom: 4px;
              background-color: #eaac0c;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }
            /* Rounded sliders */
            &.round {
              border-radius: 34px;
            }

            &.round:before {
              border-radius: 50%;
            }
          }
          input:checked + .slider {
            // background-color: #2196F3;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #ffffff;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px);
          }
        }
      }
    }
  }
}
