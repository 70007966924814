@use "../../../../../assets/sass/index" as *;
.pd_each_bank_card {
  background: radial-gradient(100% 100% at 0% 0%, #173e6f 0%, #2e3b4a 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  backdrop-filter: blur(25.8863px);
  border-radius: 18px;
  padding: 25px;
  cursor: pointer;
  .top {
    display: flex;
    justify-content: flex-end;
    img {
      width: 34px;
    }
  }
  .chip {
    margin-top: 16px;
  }
  .name_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 40px;
    p {
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
      color: #ffffff;
    }
  }
  p.number {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.06em;
    color: #ffffff;
  }
}
