@use "../../../../assets/sass/index" as *;
.pd_each_bank_ice {
  background-color: rgba($color: #e5e5e5, $alpha: 0.1);
  min-height: 100vh;
  padding-bottom: 20px;

  .back_link {
    text-decoration: none;
  }

  .cover_each_bank_ice {
    width: 100%;
    .stat_btn {
      @include stat_btn;
    }

    .balance-box {
      background: #ffffff;
      border: 0.784026px solid #e5e5e5;
      box-sizing: border-box;
      padding: 28px 60px;
      display: flex;
      justify-content: space-between;
      .left_box {
        display: flex;
        align-items: flex-start;
        .flag {
          width: 30px;
          margin-right: 30px;
        }
        .available_box {
          .top_side {
            display: flex;
            align-items: center;
            p {
              font-weight: 400;
              font-size: 9.7333px;
              line-height: 12px;
              color: #000000;
            }
            img {
              margin-left: 10px;
            }
          }
          .money {
            font-weight: 600;
            font-size: 34px;
            line-height: 42px;
            letter-spacing: 0.02em;
            color: #000000;
          }
        }
      }
      .right_box {
        display: flex;
        position: relative;
        .each_btn {
          background: #f5faff;
          text-decoration: none;
          cursor: pointer;
          border: 0.6px solid #cbddf3;
          box-sizing: border-box;
          box-shadow: 18.4376px 9.2188px 18.4376px #edf5ff;
          border-radius: 9.2188px;
          padding: 12px 19px;
          height: 41px;
          width: max-content;
          margin-right: 24px;
          display: flex;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          align-items: center;
          color: #173e6f;
          img {
            margin-right: 5px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .drop_side {
          position: absolute;
          background: #ffffff;
          border: 0.8px solid #f4f4f4;
          box-sizing: border-box;
          box-shadow: 0px 20px 20px #f9fbff;
          border-radius: 18px;
          padding: 40px 23px;
          top: 45px;
          width: 231px;
          right: 20px;
          transform: scale(0);
          transform-origin: top left;
          transition: transform 0.2s ease;
          &.show {
            transform: scale(1);
          }
          button {
            border: none;
            background: none;
            margin: 0;
            box-shadow: none;
            font-weight: 400;
            font-size: 14.9005px;
            line-height: 22px;
            color: #000000;
          }
        }
      }
    }
    .history_box {
      background: #ffffff;
      border: 0.8px solid #f4f4f4;
      box-sizing: border-box;
      box-shadow: 0px 20px 20px #f9fbff;
      border-radius: 18px;
      width: 90%;
      margin: auto;
      margin-top: 55px;
      .box_title {
        @include box_title;
      }
      .empty_history {
        width: 100%;
        padding: 105px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        p.noTran {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          text-align: center;

          color: #0d458a;
        }
        p.sub_noTran {
          font-weight: 300;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          text-align: center;

          color: #000000;
          margin-top: 7px;
        }
      }
      .full_history {
        padding: 22px 48px;
        p.day {
          font-weight: 300;
          font-size: 12px;
          line-height: 10px;
          color: #818181;
          margin-bottom: 25px;
        }
      }
      .view_history {
        padding: 26px 51px;
        width: 100%;

        .about_view_history {
          padding-left: 120px;
          margin-top: 17px;
          .track {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #0d458a;
            margin-bottom: 29px;
          }
          .conv_div {
            display: flex;
            width: 60%;
            justify-content: space-between;
            align-items: center;
            .left_conv {
              display: flex;
              align-items: center;
            }
          }
          .details_box {
            width: 40%;
            margin: auto;
            margin-top: 26px;
            .amount_type {
              font-weight: 600;
              font-size: 22px;
              line-height: 27px;
              text-align: center;
              color: #173e6f;
              margin-bottom: 36px;
            }
            .cover_all_det {
              .each_det {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                .right_det {
                  p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 40px;
                    color: #000000;
                  }
                }
                .left_det {
                  display: flex;
                  img {
                    margin-right: 5px;
                    width: 16px;
                  }
                  p {
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 40px;
                    text-align: right;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
