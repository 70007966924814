@use "../../assets/sass/index" as *;
.pd_market_page {
  #market_page_page_description_wrapper {
    padding: 0 89px 0 39px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cover_all {
    width: 100%;
    padding: 4px 89px 48px;
    @include mq(768px) {
      padding: 52px 40px;
    }
    .filterDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mq(768px) {
        flex-direction: column;
        align-items: unset;
      }

      // @include stat_btn;
      display: flex;
    }
    .btn_cover {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .filter_btn {
        background: #ffffff;
        border: 1px solid #c5cfdc;
        box-sizing: border-box;
        border-radius: 112.23px;
        outline: none;
        padding: 10px 15px;
        font-weight: 400;
        font-size: 13.1492px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        margin-right: 21px;
        cursor: pointer;
        @include mq(650px) {
          margin-bottom: 5px;
        }
        &.active {
          background: #eaac0c;
          color: white;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .extraFilter {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .each_extra_filter {
        margin-right: 35px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        p {
          font-weight: 300;
          font-size: 13.1492px;
          line-height: 16px;
          text-align: center;
          color: #5c5c5c;
          padding: 0 5px;
        }

        .line {
          border-radius: 20px 20px 0px 0px;
          width: 100%;
          margin-top: 2px;
          height: 3.65px;
        }
        &.active {
          p {
            color: #0d458a;
            font-weight: 600;
          }
          .line {
            background: #eaac0c;
          }
        }
      }
    }
    .marketTable {
      width: 100%;
      margin-top: 24px;

      .center {
        text-align: center;
      }

      .overflow {
        width: 100%;
        overflow-x: auto;
        background: #ffffff;
        border: 0.736708px solid #e5e5e5;
        box-sizing: border-box;
        box-shadow: 0px 24.8508px 24.8508px rgba(243, 243, 243, 0.4);
        border-radius: 11.8778px;
        @include webkit2;
        table {
          @include table_mixin;
          .asset-row {
            cursor: pointer;
          }
        }
      }
      .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-top: 34px;
        .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
          background-color: $primary;
        }
      }
    }
  }
}

.growth {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14.4305px;
  line-height: 18px;
  text-align: center;
  p {
    margin-left: 3px;
    color: #000000;
  }
  &.green {
    p {
      // color: #34e36f;
    }
  }
  &.red {
    p {
      // color: #f34018;
    }
  }
}
.risk {
  p {
    font-weight: 600;
    font-size: 13.1492px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }

  .line {
    border-radius: 20px 20px 0px 0px;
    // width: 90%;
    margin: auto;
    margin-top: 14px;
    // height: 3.65px;
    width: 45px;
    height: 5px;
    background-color: #34e36f;
    margin-bottom: 0 !important;
  }
}
.pd_currency_page {
  .main_page {
    width: 100%;
    padding: 39px 89px;
    @include mq(768px) {
      padding: 39px 40px;
    }
    .abount_currency {
      display: flex;
      margin-top: 58px;
      justify-content: space-between;
      align-items: flex-start;
      @include mq(1100px) {
        flex-direction: column;
      }
      .left {
        @include mq(700px) {
          width: 100%;
        }
        .rank {
          margin-bottom: 5px;
          font-weight: 400;
          font-size: 13.5864px;
          line-height: 17px;
          color: #000000;
        }
        .flag_explain {
          display: flex;
          align-items: center;
          margin-bottom: 22px;
          @include mq(500px) {
            flex-wrap: wrap;
          }
          img {
            height: 30px;
            width: 39px;
            margin-right: 9px;
          }
          p {
            display: flex;
            align-items: center;
            .currency_code {
              font-weight: 700;
              font-size: 41.1097px;
              line-height: 50px;
              color: #173963;
              display: block;
              margin-right: 9px;
              @include mq(700px) {
                font-size: 25px;
              }
              @include mq(380px) {
                font-size: 18px;
                margin-right: 4px;
              }
            }
            .currency_name {
              font-weight: 300;
              font-size: 27.26px;
              line-height: 33px;
              color: #173963;
              @include mq(700px) {
                font-size: 15px;
              }
              @include mq(380px) {
                font-size: 12px;
              }
            }
          }
          .track {
            background: #f1f7fe;
            border-radius: 10px;
            border: none;
            margin-left: 23px;
            font-weight: 400;
            font-size: 10.1492px;
            line-height: 12px;
            color: #0d458a;
            cursor: pointer;
            display: flex;
            padding: 0px 20px;
            align-items: center;
            @include mq(500px) {
              margin-left: 0;
            }
            img {
              width: 12px;
              margin-right: 5px;
            }
          }
        }
        .trend {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .price {
            font-weight: 400;
            font-size: 13.1492px;
            line-height: 16px;
            color: #000000;
            margin-bottom: 8px;
          }
          .line {
            display: flex;
            align-items: center;
            margin: 0 12px 0 3px;
            margin-bottom: 8px;
            margin-left: auto;
            margin-right: auto;
            img {
            }
            p {
              font-weight: 500;
              font-size: 13.1492px;
              line-height: 16px;
              margin-left: 4px;
              color: #000000;
            }
          }
          .variation {
            display: flex;
            margin-right: 20px;
            margin-bottom: 8px;

            .title {
              font-weight: 400;
              font-size: 13.1492px;
              line-height: 16px;
              margin-right: 1.84px;
              color: #000000;
            }
          }
          .riskRating {
            display: flex;
            margin-bottom: 8px;
            .risktext {
              font-weight: 400;
              font-size: 13.1492px;
              line-height: 16px;
              margin-right: 6px;
              color: #000000;
            }
          }
        }
      }
      .right {
        a.invest {
          background: #3aa64c;
          box-shadow: 0px 21.5746px 53.9365px rgba(0, 0, 0, 0.1);
          border-radius: 16.1809px;
          color: white;
          height: 58px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          margin-top: 20px;
          width: 220px;
        }
      }
    }
    .converting {
      margin-top: 37px;
      .first_text {
        color: #173963;
        text-align: left;
        margin-bottom: 10px;
      }
      .convert_box {
        display: flex;
        align-items: center;
        @include mq(768px) {
          flex-direction: column;
          width: max-content;
        }
        @include mq(500px) {
          flex-direction: column;
          width: 100%;
        }
        .each_convert {
          background: #ffffff;
          width: 300px;
          border: 0.7px solid #c7dcf7;
          box-sizing: border-box;
          border-radius: 10px;
          padding: 17px 23px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mq(500px) {
            width: 100%;
          }
          &.hard {
            border: 0.7px solid #173963;
            border-radius: 10px 10px 0px 0px;
          }
          .right {
            // background: #eff5ff;
            // border-radius: 6.55855px;
            // padding: 13px 9.8px;
            // width: 110px;
          }
          .left {
            .changeText {
              font-weight: 300;
              font-size: 13.3331px;
              line-height: 16px;
              margin-bottom: 10px;
              color: #656565;
            }
            .number {
              font-weight: 600;
              font-size: 22.3331px;
              line-height: 27px;
              color: #173963;
            }
          }
        }
        .midImg {
          margin: 0 11px;
          @include mq(768px) {
            margin: 10px 0;
          }
        }
      }
    }
    .conv_rating {
      display: flex;
      margin-top: 16px;
      .normal {
        font-weight: 400;
        font-size: 10.043px;
        line-height: 12px;
        color: #000000;
        margin: 0 6px;
      }
      .dollar {
        font-weight: 600;
        font-size: 10.5748px;
        line-height: 13px;
        text-align: right;
        color: #000000;
        margin-left: 7px;
      }
    }
    .date {
      font-weight: 400;
      font-size: 7.64677px;
      line-height: 9px;
      margin-top: 14px;
      color: #000000;
    }
    .flex_container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 56px;
      width: 100%;

      @include mq(1100px) {
        flex-direction: column;
      }

      .chart_box {
        @include chart_box;
      }
      .topCountries {
        width: 29%;
        background: #ffffff;
        border: 0.8px solid #f4f4f4;
        box-sizing: border-box;
        box-shadow: 0px 20px 20px #f9fbff;
        border-radius: 18px;

        padding: 23.5px 27px;

        @include mq(1100px) {
          width: 100%;
        }
        .first_text {
          margin-bottom: 38px;
          color: #000000;
          text-align: left;
        }
      }
    }

    .information_container {
      @include info_container;
    }
    .news_container {
      .first_text {
        margin: 70px 0;
        text-align: left;
        color: #000000;
      }
      .news_flex {
        display: grid;
        width: 100%;
        gap: 30px;
        grid-template-columns: auto auto auto auto;
        @include mq(1100px) {
          width: 100%;
        }
        @include mq(900px) {
          grid-template-columns: auto auto;
        }
        @include mq(600px) {
          grid-template-columns: auto;
        }
      }
    }
  }
}
