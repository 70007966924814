@use "../../assets/sass/index" as *;

.pd_progress_bar {
  width: 100%;
  background: #e5dfdf;

  box-sizing: border-box;

  border-radius: 10px;
  height: 7px;
  .progress {
    background: #eaac0c;
    border-radius: 10px;
    width: 0;
    height: 100%;
    &.start {
      width: 20%;
    }
    &.almost {
      width: 70%;
    }
    &.end {
      width: 100%;
    }
  }
}
