.transfer {
  display: flex;
  /* padding: 50px; */
  padding: 7rem 11rem;
  gap: 6.5rem;
}

.transfer p {
  margin-left: 0;
}

.transfer-left {
  flex: 1;
  /* padding: 30px; */
}

.transfer-right {
  /* flex: 1.5; */
  flex: 1.2;
  /* padding: 20px; */
}

.transfer-card .success {
  padding: 5px 15px;
  display: flex;
  justify-content: flex-end;
  width: 120px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  top: 2%;
  left: 73%;
  margin-top: 15px;
}

.success > p {
  /* font-family: 'SQ Market'; */
  font-weight: 400;
  font-size: 13.8355px;
  line-height: 17px;
  color: #3aa64c;
}

.transfer-left .transfer-card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 3.72459px 27.9345px rgba(0, 0, 0, 0.1);
  border-radius: 18.623px;
  padding: 40px 35px;
  min-width: 70%;
  position: relative;
}

.transfer-card .countries {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.transfer-card .countries img {
  margin-right: 5px;
}

.transfer-card .transfer-details {
  margin-bottom: 35px;
}

.transfer-details .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.transfer-details .details p {
  /* font-family: 'SQ Market'; */
  font-weight: 400;
  font-size: 11.3135px;
  line-height: 14px;
  color: #000000;
}

.transfer-details .details p:last-child {
  font-weight: 700;
}

.transfer-card .recipient {
  margin-bottom: 25px;
}

.transfer-card .transfer-amount > h5 {
  /* font-family: "SQ Market"; */
  font-weight: 600;
  font-size: 20.7954px;
  line-height: 25px;
  color: #0d458a;
  text-align: center;
}
