@use "../../../../assets/sass/index" as *;
.pd_account_ice {
  .list_flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .total_div {
    margin-bottom: 50px;
    .top_side {
      display: flex;
      align-items: center;
      .balance {
        font-weight: 300;
        font-size: 12.3893px;
        line-height: 15px;
        margin-right: 10px;
        color: #5c5c5c;
      }
      .pd_convert_flag_drop_down {
        background: #eff5ff;
        border-radius: 8.38704px;
        padding: 6px 7px;
        width: 70px;
        .drop_down {
          left: 0px;
          top: 40px;
        }
        .showCurrency {
          .smallFlag {
            width: 12px;
          }
          p {
            font-size: 9px;
          }
        }
      }
    }
    p.totalamount {
      font-weight: 600;
      font-size: 26px;
      margin-top: 10px;
      line-height: 33px;
      letter-spacing: 0.02em;

      color: #000000;
    }
  }

  .emptyaccount {
    margin: auto;
    width: max-content;
    p {
      font-weight: 300;
      font-size: 16.1554px;
      line-height: 20px;
      /* identical to box height */

      text-align: center;

      color: #000000;
    }
  }
}
