@use "../../assets/sass/index" as *;

.input-icon-wrap {
  background: #fafbfc;
  /* Neutral (Light) / N40 */

  box-sizing: border-box;

  background: white;
  width: 100%;
  border-radius: 6px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &.rounded {
    border-radius: 6px;
  }
  &.icon-placeholder {
    input {
      padding-left: 45px !important;
    }
  }
  .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    width: 100%;
  }
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    margin: 0;
    border: 0.7px solid #c9c9c9;
    height: 48px;
    border-radius: 6px;
  }
  .ReactFlagsSelect-module_selectOptions__3LNBJ {
    overflow-x: hidden;
    @include webkit;
  }
  .ReactFlagsSelect-module_filterBox__3m8EU {
    width: 96%;

    margin: auto;
    input {
      margin: 0;
      padding: 6px 7px !important;
      border-radius: 6px !important;
    }
  }
  .icon {
    background: transparent;
    position: absolute;
    top: 0;
    padding: 0 0.8rem;
    bottom: 0;
    border: none;
    font-size: 12px;
    background-color: inherit;
    z-index: 2;
    height: 90%;
    margin: 0.1rem;
    margin-right: 19px;
    @include flex();
    svg {
      width: 14px;
      height: 14px;
    }
    color: #718096;
    &:disabled {
      pointer-events: none;
      cursor: text;
    }
    &.startIcon-left {
      left: 0;
      border-radius: 30px 0 0 30px;
    }
    &.startIcon-right {
      right: 0;
      border-radius: 30px 0 0 30px;
    }
    &.icon-right {
      right: 0;
      cursor: pointer;
    }
  }
  &.select-wrap {
    .icon {
      cursor: auto;
      color: $primary;
      pointer-events: none;
      padding: 0 0.1rem;

      svg {
        fill: $primary;
        width: 18px;
        height: 18px;
      }
    }
    select {
      padding-right: 1.3rem !important;
      cursor: pointer;
    }
  }
  input,
  .input select {
    height: 100% !important;
  }

  /*custom select arrow*/
  select::-ms-expand {
    display: none;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .upload-pdf {
    width: 100%;
    .input {
      height: 45px;
      max-height: 45px;
      @include flex($justify: space-between);
      padding: 0 0.3rem 0 1rem;
    }
    button {
      height: 85% !important;
      color: white !important;
    }
  }
  input:not([type="checkbox"]):not([type="radio"]),
  select,
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
  .input,
  textarea,
  .select-mui {
    background: transparent;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
    padding: 0 1rem;
    border-radius: 6px;
    font-size: 12px;
    flex-grow: 2;
    line-height: 14px;
    color: var(--textClr);
    border: 0.7px solid #c9c9c9;
    &:focus {
      border: 1px solid #a0aec0;
      outline: none;
    }
  }

  textarea {
    border-radius: 6px !important;
    padding: 15px !important;
  }
  ::placeholder {
    color: #b5b5bd;
  }
}

// create a div with classname of form-group-wra to arrange input in 2's
.form-group-wrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @include mq(600px) {
    flex-direction: column;
  }
  .form-group {
    width: 48%;
    @include mq(600px) {
      width: 100%;
    }
  }
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
  width: 100%;

  label {
    @include label;
  }
  .cover_label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    img {
      margin-bottom: 8px;
    }
  }

  &.half-width {
    width: 48%;
  }
}
.filter-select {
  width: fit-content;
  max-width: 197px;
  text-transform: capitalize !important;
}
.custom-checkbox {
  border: 0.7px solid #c9c9c9;
  margin: 0.4rem 0 !important;
  margin-right: 1rem !important;
  padding-left: 0.6rem;
  border-radius: 4px;
  span {
    font-size: 14px;
  }
  svg {
    font-size: 18px;
  }
  &.active {
    border-color: var(--primary);
  }
}

// material ui select
.select-mui {
  padding: 0;
  width: 100% !important;
  border-radius: 6px !important;
  background-color: white;
  font-size: 12px !important;

  svg {
    fill: var(--iconBg);
  }
  &,
  input {
    border: none !important;
  }
  fieldset {
    border-width: 1px !important;
    border-color: #c9c9c9;

    &:focus {
      border-color: $primary !important;
    }
  }
}

.menu-item {
  font-size: 12px !important;
  &.placeholder {
    color: #b5b5bd;
  }
  &:hover,
  &.Mui-selected {
    background-color: $primary !important;
    color: white !important;
  }
}

.form-group textarea {
  height: 130px !important;
  padding: 0.5rem;
  max-height: unset;
  resize: vertical;
  cursor: default;
  &.extraHeight {
    height: 330px !important;
  }
}

.is-invalid {
  border-color: #f34018 !important;
}

.input-err-msg {
  color: #ff0606;
  width: 100%;
  font-weight: 500;
  font-size: 8.51965px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  text-align: right;
}

.commentInput {
  margin-bottom: 0;
  input,
  .input-icon-wrap {
    background: transparent;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    &:focus {
      background: transparent;
    }
  }
  .input-icon-wrap {
    border-top: 1px solid rgba($color: #000000, $alpha: 0.06);

    svg path {
      fill: rgba($color: #000000, $alpha: 0.6);
    }
  }
}

// phone style
.form-group {
  .react-tel-input {
    height: 100%;
  }
  .react-tel-input .selected-flag {
    height: 40px;
  }
  .react-tel-input .form-control {
    width: 100%;
  }
}

.react-tel-input {
  border: 0.7px solid #c9c9c9;
  border-radius: 6px;
  padding: 8px 6px;
  .selected-flag {
    &:hover {
      background-color: none !important;
      background: none !important;
    }
  }
  input {
    background: transparent;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
    font-size: 12px;
    border: none !important;
    line-height: 14px;
  }
  .form-control {
    padding-left: 60px !important;
    box-shadow: none !important;
  }
  .flag {
    width: 16.24px !important;
    height: 12.58px !important;
    background-color: #edf5ff !important;
  }
  .flag-dropdown {
    background-color: white !important;
    border: none !important;
    width: 55px !important;
    background: #edf5ff !important;
    border-radius: 5px !important;
    height: 33px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 13% !important;
  }
}

.pd_custom_flag_drop_down {
  width: 100%;
  position: relative;
  padding: 10px 0;
  &:focus-within {
    .cover_all {
      .drop_box {
        .search {
          display: block;
        }
      }
    }
  }
  .cover_all {
    label {
      @include label;
    }
    .drop_box {
      @include drop_box;
      input {
        border: none;
        background: none;
        outline: none;
        width: 90%;
        height: 100%;
      }
      .search {
        display: none;
        margin-right: 9.88px;
      }
      .dropIcon {
        cursor: pointer;
        position: absolute;
        top: 45%;
        right: 20px;
      }
    }
  }

  .drop_down {
    @include drop_down;
    overflow: hidden;
  }
}
.pd_convert_flag_drop_down {
  position: relative;
  background: #eff5ff;
  border-radius: 6.55855px;
  padding: 13px 9.8px;
  width: 110px;
  .showCurrency {
    display: flex;
    cursor: pointer;
    width: 100%;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 15.3529px;
      line-height: 19px;
      width: 36px;
      color: #173963;
      margin: 0 9px;
    }
  }
  .drop_box {
    @include drop_box;
    width: 100%;
    border: none;
    border-bottom: 0.7px solid #dbdbdb;
    border-radius: 0;
    input {
      border: none;
      background: none;
      outline: none;
      width: 90%;
      height: 100%;
    }
    .search {
      // display: none;
      margin-right: 9.88px;
    }
  }
  .drop_down {
    // display: none;
    @include drop_down;
    overflow: hidden;
    width: 301px;
    right: 0px;
    top: 50px;
    padding: 0 !important;
    padding-bottom: 30px !important;
    background: #ffffff;
    border: 0.7px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 10px;
    .overflow {
      padding: 26px 5px 40px 20px;
    }
    // &.show {
    //   display: block;
    // }
    overflow: hidden;
  }
}

.account_currency_selector {
  background-color: white !important;
  width: 459.93px !important;
}

.account_select_drop {
  width: 462.63px !important;
}

.show_mini_details {
  display: flex;
  align-items: center;
}

.account_droper {
  margin-left: 25rem;
  position: fixed;
}

.pd_convert_flag_drop_down {
  position: relative;
  background: #eff5ff;
  border-radius: 6.55855px;
  padding: 13px 9.8px;
  width: 110px;
  &.ice {
    background: #ffffff !important;
    border: 0.7px solid #c9c9c9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    width: 100% !important;
    position: relative;
    padding: 15px 25px;
    .showCurrency {
      position: relative;
      min-height: 16px;
      p.name {
        width: max-content !important;
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */
        margin-right: 7px;
        margin-left: 9px;
        color: #000000;
      }
      p.currency {
        width: max-content !important;
        padding: 0;
        font-weight: 300;
        font-size: 12px;
        margin: 0;
        line-height: 15px;
      }
      .arrdown {
        position: absolute;
        top: 40%;
        right: 20px;
      }
    }
    .drop_down {
      width: 100% !important;
      top: 60px !important;
    }
  }
  .showCurrency {
    div {
      font-weight: 400;
      font-size: 15.3529px;
      line-height: 19px;
      color: #173963;
      margin: 0 9px;
      cursor: pointer;
      span {
        font-weight: bold;
      }
    }
  }
  .drop_box {
    @include drop_box;
    width: 100%;
    border: none;
    border-bottom: 0.7px solid #dbdbdb;
    border-radius: 0;
    input {
      border: none;
      background: none;
      outline: none;
      width: 90%;
      // height: 100%;
    }
    .search {
      // display: none;
      margin-right: 9.88px;
    }
  }
  .drop_down {
    // display: none;
    @include drop_down;
    overflow: hidden;
    width: 301px;
    right: 0px;
    top: 50px;
    background: #ffffff;
    border: 0.7px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 10px;
    .overflow {
      padding: 20px;
      max-height: 280px;
      overflow: scroll;
    }
  }
}

.pd_each_location {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;
  img {
    width: 24px;
  }
  .code {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-left: 9px;
    margin-right: 5px;
    width: 40px;
  }
  .country_name {
    margin: 0;
    font-weight: 300;
    font-size: 12.4342px;
    line-height: 15px;
    color: #000000;
  }
  &.eachlang {
    border-radius: 3px;
    padding: 10px;
    &:hover {
      background: #173e6f;

      .country_name {
        color: white;
      }
    }
  }
}

.each_number {
  display: flex;
  align-items: center;
  img {
    width: 16px;
    margin-right: 6px;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 11.7045px;
    line-height: 14px;

    color: #000000;
  }
}

.pd_input_phone {
  width: 100%;

  padding: 10px 0;
  label {
    @include label;
  }
  .pd_mobile_container {
    background: #ffffff;
    border: 0.7px solid #c9c9c9;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8.12px 6.5px;
    display: flex;
    position: relative;
    .number_div {
      background: #edf5ff;
      border-radius: 5px;
      // width: 85px;
      padding: 10px 9.5px;
      .current_hold {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        img.flag {
          width: 16px;
        }
        p {
          font-weight: 600;
          font-size: 11.7045px;
          line-height: 14px;
          color: #000000;
          margin: 0 10.4px 0 6.25px;
        }
        img.drop {
        }
      }
    }
    input {
      border: none;
      background: none;
      flex: auto;
      outline: none;
      padding: 10px;
    }
    .drop_down {
      @include drop_down;
      margin-top: 0;
      left: 0;
      top: 48px;
    }
  }
  .msg_side {
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
    }
    p {
      margin-top: 2px;
      font-weight: 300;
      font-size: 10px;
      line-height: 18px;
      color: #000000;
    }
  }
}

.upload_file {
  margin-top: 35px;
  width: 100%;
  p {
    margin: 0;
  }
  .text_head {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    color: #0d458a;
    margin-bottom: 20px;
  }
  .sub_text {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 17.36px;
    color: #5c5c5c;
  }
  .cover_file {
    margin-bottom: 97px;
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5faff;
      border: 1px dashed #0d458a;
      box-sizing: border-box;
      border-radius: 8.42286px;
      cursor: pointer;
      height: 258px;
      flex-direction: column;
      .loadedimg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .picard_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 35.22px;
        img {
          margin-bottom: 17.92px;
        }
        p {
        }
      }
      p.upload {
        margin: 0;
        text-align: center;
        padding: 16px 57px;
        border: none;
        font-weight: bold;
        background: #ffffff;

        border-radius: 6px;

        color: $primary;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-top: 17px;
      button,
      label {
        border: none;
        background: none;
        margin: 0;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14.4368px;
        line-height: 18px;
        text-align: right;
        img {
          margin: 0;
          margin-right: 7px;
        }
        &.reuplaod {
          color: $primary;
        }
        &.delete {
          color: #f34018;
        }
      }
    }
  }
}
