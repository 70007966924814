@use "../../assets/sass/index" as *;

.pd_sidebar {
  display: flex;
  flex-direction: column;
  padding: 43px 23px;
  background: #f9fbff;
  width: 237px;
  position: sticky;
  top: 0;
  min-height: 100vh;
  overflow-x: hidden;

  padding: 43px 10px;
  transition: width 0.5s ease;
  .link_side {
    width: 100%;
    display: flex;
    flex-direction: column;
    .item {
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 16px 22px;
      border-radius: 10px;
      margin-bottom: 22px;
      .iconSide {
        margin-right: 22px;
        width: 18px;
        height: 18px;
        path {
          fill: #525252;
        }
      }
      p {
        font-weight: 400;
        font-size: 13.3893px;
        line-height: 16px;
        color: #525252;
      }
      &.active {
        background: #ffffff;
        border: 0.5px solid #deedff;
        box-shadow: 0px 20px 20px #edf5ff;
        border-left: 1px solid #eaac0c;
        .iconSide {
          path {
            fill: #eaac0c;
          }
        }
        p {
          font-weight: 600;
          font-size: 13.3893px;
          line-height: 16px;

          color: #173e6f;
        }
      }
      &.support2 {
        margin-top: 50px;
        background: #ffffff;
        border: 0.4px solid #edf5ff;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        position: absolute;
        top: 600px;
        width: 177px;
        .iconSide2 {
          margin-right: 22px;
          width: 18px;
          height: 18px;
        }
        p {
          font-weight: 600;
          font-size: 12.3893px;
          line-height: 15px;

          color: #0d458a;
        }
      }
    }
  }
  .open_up {
    margin-bottom: 57px;
    display: flex;
    // display: none;
    transition: all 0.01s ease;
    cursor: pointer;
    align-items: center;
    padding-left: 0px;

    .rightimg {
      margin-right: 3px;
    }
    .midimg {
      margin-right: 0;
    }

    .leftimg {
      display: none;
    }
  }

  &.closed {
    width: 68px;
    padding: 43px 11px;
    .link_side {
      .item {
        padding: 16px 14px;
        &.support2 {
          width: 50px;
          .iconSide2 {
            margin-right: 0;
          }
        }
        p {
          display: none;
        }
        .iconSide {
          margin-right: 0;
        }
      }
    }
    .open_up {
      .rightimg {
        display: none;
      }
      .leftimg {
        display: block;
        transform: rotate(180deg);
      }
    }
  }
}
