@use "../../assets/sass/index" as *;

.pd_each_transaction {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 29px;
  .left {
    display: flex;
    align-items: center;
    .arr_box {
      background: #f2f8ff;
      margin-right: 12px;
      border-radius: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 12px;
      }
    }
    .text_side {
      .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }
      .money {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #173e6f;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
  }
}
button.status {
  border: none;
  outline: none;

  border-radius: 4px;
  font-weight: 400;
  font-size: 10.4413px;
  line-height: 9px;
  text-align: center;
  letter-spacing: -0.03em;
  margin-right: 17px;
  height: 20px;
  width: 64px;
  &.progress {
    background: #dbebff;
    color: #173e6f;
  }
  &.success {
    background: #dfffe4;
    color: #3aa64c;
  }
  &.fail {
    background: #ffd9d0;
    color: #f34018;
  }
}
