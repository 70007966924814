.pd_each_chart_indicator {
  padding: 10px 7px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 13px;
  background: #ffffff;
  border: 0.887989px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 22.1997px 22.1997px #f9fbff;
  border-radius: 11.0999px;
  margin-top: 7px;
  &:last-child {
    margin-right: 0;
  }
  .color_box {
    width: 11px;
    height: 11px;
    border-radius: 50%;
  }
  .about {
    margin: 0 16px 0 11px;
  }
  .code {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    color: #000000;
  }
  .money {
    font-weight: 300;
    font-size: 6.4654px;
    line-height: 8px;

    color: #000000;
  }
  .flag {
    width: 11px;
  }
}
