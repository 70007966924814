@use "../../../assets/sass/index" as *;
.pd_home {
  width: 100%;
  .home_flex {
    width: 100%;
    display: flex;
    background: #f9fbff;
    @include mq(1000px) {
      flex-direction: column;
    }
    .white_part {
      // background: white;
      padding-left: 47px;
      padding-top: 30px;
      width: 65%;
      @include mq(1000px) {
        width: 100%;
      }
      .welcome {
        font-weight: 400;
        font-size: 22.7369px;
        line-height: 28px;
        color: #173963;
        margin-bottom: 5px;
        span {
          font-weight: 600;
        }
      }
      .sub_welcome {
        font-weight: 300;
        font-size: 11.5361px;
        line-height: 14px;
        color: #000000;
      }
      .pink_box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .cover {
          background: #fff8e6;
          border-radius: 11.3294px 11.3294px 0px 0px;
          width: 210px;
          display: flex;
          align-items: center;
          padding: 11px 15px;
          img {
            margin-left: 12px;
          }
          p {
            font-weight: 500;
            font-size: 11.3294px;
            line-height: 14px;
            color: #000000;
            span {
              color: #3aa64c;
              font-weight: 600;
            }
          }
        }
      }
      .tableSide {
        width: 98%;
        margin-top: 60px;
        padding-bottom: 30px;
        p.topic {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 3px;
          color: #000000;
        }
        p.sub {
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
          margin-bottom: 17px;
        }
        .btn_box {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          @include mq(600px) {
            flex-direction: column;
            align-items: unset;
          }
          .btn_cover {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .filter_btn {
              background: #ffffff;
              border: 1px solid #c5cfdc;
              box-sizing: border-box;
              border-radius: 112.23px;
              outline: none;
              padding: 10px 15px;
              font-weight: 400;
              font-size: 13.1492px;
              line-height: 16px;
              text-align: center;
              color: #000000;
              margin-right: 21px;
              cursor: pointer;
              @include mq(650px) {
                margin-bottom: 5px;
              }
              &.active {
                background: #eaac0c;
                color: white;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          a {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-decoration: none;

            color: #173e6f;
          }
        }
        .cover_table {
          background: #ffffff;
          border: 0.8px solid #f4f4f4;
          box-sizing: border-box;
          box-shadow: 0px 20px 20px #f9fbff;
          border-radius: 18px;
          margin-top: 19px;
          .over_table {
            width: 100%;
            @include webkit2;
            overflow-x: auto;
            table {
              @include table_mixin;
              min-width: 500px;
            }
          }
        }
      }
      .promo_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        background: #ffffff;
        padding: 6px 37px 0 10px;
        border: 0.8px solid #f4f4f4;
        box-sizing: border-box;
        box-shadow: 0px 20px 20px #f9fbff;
        border-radius: 18px;
        margin: 32px 0 90px;
        @include mq(600px) {
          display: none;
        }
        img {
          width: 30%;
        }
        .promo_text {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #173963;
          width: 30%;
          span {
            color: #3aa64c;
          }
        }
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background: #3aa64c;
          box-shadow: 0px 17.4692px 43.6731px rgba(0, 0, 0, 0.1);
          border-radius: 13.1019px;
          color: white;
          width: 170px;
          height: 47px;
          width: 30%;
        }
      }
    }
    .dark_part {
      padding: 16px 31px;

      width: 35%;
      box-shadow: 0px 13.9706px 13.9706px #f9fbff;
      @include mq(1000px) {
        width: 100%;
        margin-top: 20px;
      }
      .convBox {
        background: #ffffff;
        border-radius: 20px;
        z-index: 2;
        padding: 26px 36px;
        width: 100%;

        .title {
          font-weight: 400;
          font-size: 9px;
          line-height: 11px;
          margin-bottom: 16px;
          color: #173963;
        }

        .cover_transaction {
          margin-top: 34px;
          .title_flex {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            p {
              font-weight: 600;
              font-size: 13.3893px;
              line-height: 16px;
              color: #000000;
            }
            a {
              font-weight: 400;
              font-size: 13.3893px;
              line-height: 16px;
              text-decoration: none;
              color: #173e6f;
            }
          }
          .day {
            font-weight: 300;
            font-size: 11.4815px;
            line-height: 10px;
            /* identical to box height, or 86% */

            color: #818181;
            margin: 24px 0 25px;
          }
          .empty_tran {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding-bottom: 46px;
            img {
              margin-top: 55px;
              display: block;
            }
            p.no_transaction {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              margin-top: 18px;
              color: #0d458a;
            }
            p.desc {
              margin-top: 7px;
              font-weight: 300;
              font-size: 12px;
              line-height: 16px;
              text-align: center;

              color: #000000;
            }
          }
        }
      }
    }
  }
}
