@use "../../../../assets/sass/index" as *;

.pd_each_market {
  .main_page {
    padding: 24px 70px !important;
    .abount_currency {
      .right {
        a {
          background: #3aa64c;
          box-shadow: 0px 21.5746px 53.9365px rgba(0, 0, 0, 0.1);
          border-radius: 16.1809px;
          text-decoration: none;
          font-weight: 500;
          font-size: 20.1423px;
          line-height: 25px;
          /* identical to box height */
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 50px;
        }
      }
    }
    .news_flex_grid {
      @include news_flex_grid;
    }
  }
}
