@use "../../../assets/sass/index" as *;

.pd_ref_page {
  background-color: rgba($color: #e5e5e5, $alpha: 0.09);
  min-height: 100vh;
  .filter_ice {
    @include filter_ice;
  }
  .main_ref {
    padding: 49px 37px;
    .cover_ref {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include mq(900px) {
        flex-direction: column;
      }
      .left_ref {
        width: 65%;
        @include mq(900px) {
          width: 100%;
        }
        .top_left_ref {
          width: 100%;
          background: #ffffff;
          border: 0.518739px solid #e5e5e5;
          box-shadow: 0px 17.4982px 17.4982px rgba(243, 243, 243, 0.4);
          border-radius: 15.7484px;
          .top_side {
            padding: 21px 28px;
            width: 100%;
            &.pad {
              border-bottom: 0.5px solid #e5e5e5;
            }
            .ref_overview {
              display: flex;
              align-items: center;
              p.see {
                font-weight: 300;
                font-size: 12.3893px;
                line-height: 15px;
                margin-right: 13px;
                color: #5c5c5c;
              }
              .pd_convert_flag_drop_down {
                padding: 7px !important;
                border-radius: 8.38704px !important;
                width: 63px !important;
                .drop_down {
                  &.show {
                    left: 0;
                  }
                }
                .smallFlag {
                  width: 12px !important;
                }
                .showCurrency {
                  p {
                    font-size: 9px;
                    width: unset;
                    margin: 0 3px;
                  }
                }
              }
              .ques {
                margin-left: 9px;
              }
            }
            .select_flag {
              display: flex;
              align-items: center;

              .each_ref_flag {
                background: #ffffff;
                border: 0.812398px solid #dedede;
                border-radius: 91.175px;
                margin-right: 22px;
                cursor: pointer;
                width: 81px;
                height: 31px;
                display: flex;
                align-items: center;
                justify-content: center;
                .flag {
                  width: 19px;
                  margin-right: 7px;
                }
                .code {
                  font-weight: 400;
                  font-size: 13.4727px;
                  line-height: 16px;
                }
                &.active_code {
                  background: #deedff;
                  border: 0.812398px solid #173963;
                }
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
          .bottom_side {
            padding: 30px 36px;
            display: flex;
            align-items: center;
            .each_total {
              margin-right: 90px;
              &:last-child {
                margin-right: 0;
              }
              .earn {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                margin-bottom: 10px;
              }
              .amount {
                font-weight: 600;
                font-size: 30px;
                line-height: 37px;

                color: #000000;
              }
            }
          }
        }
        .bottom_left_ref {
          width: 100%;
          margin-top: 30px;
          background: #ffffff;
          border: 0.518739px solid #e5e5e5;
          box-shadow: 0px 17.4982px 17.4982px rgba(243, 243, 243, 0.4);
          border-radius: 15.7484px;
          padding: 50px 38px;
          p.people {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 20px;
            color: #000000;
          }
          .noref {
            padding: 47px 150px;
            width: 100%;
            img {
              display: block;
              margin: auto;
              width: 122px;
            }
            p.data {
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              text-align: center;
              margin-bottom: 6px;
              color: #000000;
            }
            p.appear {
              font-weight: 300;
              font-size: 12px;
              line-height: 15px;
              text-align: center;

              color: #000000;
            }
          }
          .cover_people {
            .each_people {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 22px;
              cursor: pointer;
              &:last-child {
                margin-bottom: 0;
              }
              .left_people {
                display: flex;
                align-items: center;
                .about_person {
                  margin-left: 14px;
                  .name {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    margin-bottom: 5px;
                  }
                  .money {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;

                    color: #173e6f;
                  }
                }
              }
            }
          }
        }
      }
      .right_ref {
        width: 33%;
        background: #ffffff;
        border: 0.518739px solid #e5e5e5;
        box-shadow: 0px 17.4982px 17.4982px rgba(243, 243, 243, 0.4);
        border-radius: 15.7484px;
        padding: 31px 33px;
        @include mq(900px) {
          width: 100%;
          margin-top: 20px;
          padding: 10px 20px;
        }
        .present {
          width: 90px;
          margin: auto;
          display: block;
        }
        .get {
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
          /* or 145% */

          text-align: center;

          color: #000000;
          margin: 20px 0 35px;
        }
        .share {
          font-weight: 700;
          font-size: 13.9973px;
          line-height: 17px;
          /* identical to box height */

          text-align: center;

          color: #173e6f;
        }
        .copy {
          font-weight: 400;
          font-size: 12.9973px;
          line-height: 16px;
          text-align: center;
          margin: 7px 0 28px;
          color: #000000;
        }
        .copy_box {
          display: flex;
          align-items: center;
          width: 100%;
          background: #f2f6fe;
          border-radius: 70px;
          padding: 9px 22px;
          justify-content: space-between;

          margin: auto;
          .link {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */

            color: #5c5c5c;
          }
          button {
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            background: none;
            border: none;
            color: #0d458a;
            width: 55px;
          }
        }
        .icon_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 28px 0 38px;
          img {
            margin-right: 14px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .terms {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: center;

          color: #173e6f;
        }
      }
    }
  }
}
