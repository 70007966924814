.trans-card {
  /* width: max-content; */
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 3.49959px 26.2469px rgba(0, 0, 0, 0.1);
  border-radius: 17.4979px;
  padding: 35px;
  display: flex;
  flex-direction: column;
}

.trans-card .trans-card-top {
  display: flex;
  margin-bottom: 20px;
}

.trans-card .trans-card-top img {
  margin-right: 12px;
}

.trans-card-top .trans-card-text {
  display: flex;
  flex-direction: column;
}

.trans-card-top .trans-card-text p:first-child {
  /* font-family: 'SQ Market'; */
  font-weight: 400;
  font-size: 19.2804px;
  line-height: 24px;
  color: #000000;
}

.trans-card-top .trans-card-text p:last-child {
  /* font-family: 'SQ Market'; */
  font-weight: 400;
  font-size: 8.0053px;
  line-height: 10px;
  color: #000000;
}
