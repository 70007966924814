@use "../../assets/sass/index" as *;

.cover_all_nav {
  position: relative;
}

.pd_navbar_dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 43px;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);

  @include mq(900px) {
    @include mob_nav;

    &.nav-opened {
      clip-path: inset(0 0 0 0);
      padding: 20px;
      padding-bottom: 120px;
      width: 280px;
      inset: unset;
      right: 10px;

      .right {
        flex-direction: column;

        .cover_drop {
          margin: 30px 0;
        }

        .toggleSide {
          margin-top: 20px;
        }
      }
    }
  }

  .page_title {
    font-weight: 600;
    font-size: 17.3893px;
    line-height: 21px;
    text-transform: capitalize;

    color: #173963;
    display: flex;

    .open_up {
      margin-bottom: 0;
      margin-right: 5px;
      padding-left: 0;
    }
  }

  .box_first {
    display: flex;
    align-items: center;

    @include mq(900px) {
      width: 60%;
    }

    .side {
      display: flex;
      align-items: center;

      &.active {
        .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
          display: block;
        }
      }

      &:first-child {
        margin-right: 17px;
      }

      &:last-child {
        margin-left: 17px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #173963;
        margin-right: 6px;
      }

      .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        color: #eaac0c !important;
      }
    }

    .separatorline {
      width: 49px;
      margin: 0 15px;
      height: 2px;
      background-color: #b2b2b2;
    }
  }

  .right {
    @include right_nav;
  }
}