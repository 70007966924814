@use "../../../assets/sass/index" as *;

.dashboard_layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .main_side {
    flex-grow: 2;
    width: calc(100% - 237px);
    .screen_content {
      width: 100%;
    }
  }
}
